<template>


  <v-container fluid class="ma-0 pa-0" style="background:black;min-height:100%;">

    

    <div :class="$vuetify.breakpoint.xsOnly ? 'imgtopbgmb2' : 'imgtopbg2'"/>

    <div :class="$vuetify.breakpoint.smAndDown ? 'imgtopbgmb' : 'imgtopbg'">
    <!-- <div> -->
    
    <pdfinfodown ref="pdfinfodown" />
    
    <!-- 
    <img aspect-ratio="370/464" style="position:absolute;right:1%;bottom:1%;opacity:1"
         :src="require('@/assets/main_diamond_ring2.png')" />
    -->

    <!-- 로그인 유도 팝업 -->
    <v-row align="center" justify="center">
      <v-dialog  persistent overlay-color="#000000FF"  v-model="login_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">KDGE</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-spacer/>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <div class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >다이아몬드 보석 판매가격 문의는 회원가입이 필요합니다.<br><span class="dialog_subtitle_eng">Inquiries about diamond jewelry sales prices require membership registration.</span></div>

                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goJoin">Join 가입하기</v-btn>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <p class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >회원이시면 로그인해주세요<br><span class="dialog_subtitle_eng">If you are a member, please log in.</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goLogin">Login 로그인</v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <v-spacer/>
          </v-card-text>

          <v-card-actions class="mt-5 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12"> <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="login_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    
    
    <v-row align="center" justify="center" >
      <v-col cols="11" md="11">
        <v-row class="mt-0 mb-10 mt-md-10 mb-md-10" style="display:block;">

          <!-- PC 해상도 -->
          <div style="margin-left:3vw;" class="hidden-xs-only mt-5">
            <div class="main_text inter_font" style="font-size:80px;line-height:80px;">kDiaGem</div>
            <div class="text-h2 main_text inter_font">Korea DIAMOND</div>
            <div class="text-h2 main_text inter_font">GEM Exchange</div>
            <div class="mt-1 text-h3 main_text inter_font">한국다이아몬드보석거래소</div>
          </div>
          <!-- 
          <v-col cols="12" class="hidden-xs-only">
            <div class="text-md-h2 text-h3 main_text">KDGE</div>
            <div class="text-md-h2 text-h4 main_text">Korea DIAMOND</div>
            <div class="text-md-h2 text-h4 main_text">GEM Exchange</div>
            <div class="text-md-h3 text-h5 main_text">한국다이아몬드보석거래소</div>
          </v-col>
          -->

          <!-- 모바일 해상도 -->
          <v-col cols="12" class="hidden-sm-and-up">
            <div style="line-height:26px" class="text-md-h2 text-h4 main_text">kDiaGem</div>
            <div style="line-height:24px" class="text-md-h2 text-h5 main_text mt-md-8">Korea DIAMOND</div>
            <div style="line-height:24px" class="text-md-h2 text-h5 main_text mt-md-8">GEM Exchange</div>
            <div style="line-height:24px" class="text-md-h3 text-h6 main_text mt-md-8">한국다이아몬드보석거래소</div>
          </v-col>

          <!-- PC 해상도 -->
          <div class="hidden-xs-only" style="margin-left:3vw;">

            <div 
                :class="$vuetify.breakpoint.mdAndDown ? 'pa-0 mt-10 d-flex justify-start' : 'pa-0 mt-10 d-flex justify-start'">

                <div style="max-width:240px;max-height:240px;text-align: center;cursor: pointer;"
                    :style="'width:'+($vuetify.breakpoint.width/5)+'px;height:'+($vuetify.breakpoint.width/5)+'px'"
                    class="d-none d-sm-flex flex-wrap justify-center" @click="type_select(4)">
                  <v-hover v-slot="{ hover }" style="overflow:hidden;">
                  <div 
                    style="max-width:210px; border-radius:0px; border:5px white solid;"
                    :style="'width:'+($vuetify.breakpoint.width/5)-30+'px'"
                    >
                    
                    <v-img
                      src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/nd_home4.png"
                      style="max-width:200px;max-height:200px"
                      :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                      :class="{'imgscaleup':hover}"
                      >
                    </v-img>
                    
                    <!-- 
                    <v-img
                      :src="require('@/assets/testimg1.png')"
                      style="max-width:200px;max-height:200px"
                      :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                      :class="{'imgscaleup':hover}"
                      >
                    </v-img>
                    -->

                  </div>
                  </v-hover>
                  <p style="color:white;width:210px;line-height:120%" 
                    :style="$vuetify.breakpoint.width < 1000 ? 'font-size:21px' : 'font-size:24px'"
                     class="mt-2 text-center" @click="type_select(4)">천연 다이아몬드<br>Natural Diamond</p>
                </div>

                <div style="max-width:240px;max-height:240px;text-align: center;cursor: pointer;margin-left:10px"
                    :style="'width:'+($vuetify.breakpoint.width/5)+'px;height:'+($vuetify.breakpoint.width/5)+'px'"
                    class="d-none d-sm-flex flex-wrap justify-center" @click="type_select(5)">
                  <v-hover v-slot="{ hover }" style="overflow:hidden;">
                  <div 
                    style="max-width:210px; border-radius:0px; border:5px white solid;"
                    :style="'width:'+($vuetify.breakpoint.width/5)-30+'px'"
                    >
                    
                    <v-img
                      src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/ld_home4.png"
                      style="max-width:200px;max-height:200px"
                      :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                      :class="{'imgscaleup':hover}"
                      >
                    </v-img>
                    
                    <!-- 
                    <v-img
                      :src="require('@/assets/testimg2.png')"
                      style="max-width:200px;max-height:200px"
                      :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                      :class="{'imgscaleup':hover}"
                      >
                    </v-img>
                    -->

                  </div>
                  </v-hover>
                  <p style="color:white;width:210px;line-height:120%" 
                    :style="$vuetify.breakpoint.width < 1000 ? 'font-size:21px' : 'font-size:24px'"
                    class="mt-2 text-center" @click="type_select(5)">팬시컬러<br>렙-그로운 다이아몬드<br>Fancy Color<br>Lab-Grown Diamond</p>
                </div>

                <div style="max-width:240px;max-height:240px;text-align: center;cursor: pointer;margin-left:10px"
                    :style="'width:'+($vuetify.breakpoint.width/5)+'px;height:'+($vuetify.breakpoint.width/5)+'px'"
                    class="d-none d-sm-flex flex-wrap justify-center" @click="type_select(1)">
                  <v-hover v-slot="{ hover }" style="overflow:hidden;">
                  <div 
                    style="max-width:210px; border-radius:0px; border:5px white solid;"
                    :style="'width:'+($vuetify.breakpoint.width/5)-30+'px'"
                    >

                      <v-img
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/gem_home5.png"
                        style="max-width:200px;max-height:200px"
                        :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                        :class="{'imgscaleup':hover}"
                        >
                      </v-img>
                      
                      <!-- 
                      <v-img
                        :src="require('@/assets/gem_home5.png')"
                        style="max-width:200px;max-height:200px"
                        :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                        :class="{'imgscaleup':hover}"
                        >
                      </v-img>
                      -->

                    </div>
                    </v-hover>
                  <p style="color:white;width:210px;line-height:120%" 
                    :style="$vuetify.breakpoint.width < 1000 ? 'font-size:21px' : 'font-size:24px'"
                    class="mt-2 text-center" @click="type_select(1)">천연보석<br>Gemstone</p>
                </div>
                
                <div style="max-width:240px;max-height:240px;text-align: center;cursor: pointer;margin-left:10px"
                    :style="'width:'+($vuetify.breakpoint.width/5)+'px;height:'+($vuetify.breakpoint.width/5)+'px'"
                    class="d-none d-sm-flex flex-wrap justify-center" @click="type_select(2)">
                  <v-hover v-slot="{ hover }" style="overflow:hidden;">
                  <div 
                    style="max-width:210px; border-radius:0px; border:5px white solid;"
                    :style="'width:'+($vuetify.breakpoint.width/5)-30+'px'"
                    >
                    <v-img
                      src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/jew_home2.png"
                      style="max-width:200px;max-height:200px"
                      :style="'width:'+($vuetify.breakpoint.width/5)-40+'px;height:'+($vuetify.breakpoint.width/5)-40+'px'"
                      :class="{'imgscaleup':hover}" 
                      >
                    </v-img>
                    </div>
                    </v-hover>
                  <p style="color:white;width:210px;line-height:120%" 
                    :style="$vuetify.breakpoint.width < 1000 ? 'font-size:21px' : 'font-size:24px'"
                    class="mt-2 text-center" @click="type_select(2)">주얼리<br>Jewelry</p>
                </div>

            </div>
          </div>

          <!-- PC 해상도 -->
          <v-row class="hidden-xs-only justify-start" dense 
            v-if="(this.$session.get('GRADE') >= 10 || this.$session.get('SUB_DAYS') >= 0) && editedItem != null"
            :style="$vuetify.breakpoint.width < 1100 ? 'margin-top:7.5vh':'margin-top:5vh'"
            >
            <label class="pl-15" style="color:white;font-size:21px;">RAPAPORT :&nbsp;</label>
            <label style="color:white;font-size:21px;cursor:pointer" @click="showImage_pdf_popup(editedItem.RAPA_ROUNDS)">Rounds</label>
            <label style="color:white;font-size:21px;">&nbsp;/&nbsp;</label>
            <label style="color:white;font-size:21px;cursor:pointer" @click="showImage_pdf_popup(editedItem.RAPA_PEARS)">Pears</label>
          </v-row>

          <!-- PC 해상도 -->
          <v-col md="6" class="pa-0 hidden-xs-only" 
            :style="$vuetify.breakpoint.width < 1100 ? 
            (this.$session.get('GRADE') >= 10 || this.$session.get('SUB_DAYS') >= 0) && editedItem != null ? 'margin-top:3vh':'margin-top:12vh' 
              :(this.$session.get('GRADE') >= 10 || this.$session.get('SUB_DAYS') >= 0) && editedItem != null ? 'margin-top:3vh':'margin-top:12vh' 
              "
            >
            <v-row class="justify-md-end justify-sm-center justify-center" justify="start">

              <v-col cols="1"/>
              <v-col cols="5" md="7" lg="6" class="ma-0 pa-0 radius_10" style="background:rgba(43, 43, 43, 1.0);height:70px">
                <a @click="govivid">
                  <v-row align="center" justify="center" class="ma-0 pa-0 mt-6">
                    <v-col cols="8" style="color:white" align="left" class="pa-0"><v-img max-width="200" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/vivd-logo.png"/></v-col>
                    <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                  </v-row>
                </a>
              </v-col>
              <v-col cols="5" md="3" lg="5"/>

              <v-col cols="1"/>
              <v-col cols="5" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1"  style="background:rgba(43, 43, 43, 1.0);height:70px">
                <a @click="gofindshop">
                  <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                    <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">Find Store</v-col>
                  </v-row>
                  <v-row align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">내 주변 소매점 찾기</v-col>
                    <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                  </v-row>
                </a>
              </v-col>
              <v-col cols="4" md="3" lg="5"/>

              <v-col cols="1"/>
              <v-col cols="5" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="gosalereq">
                <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                  <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">내 다이아몬드 보석<br>판매 가격 알아보기</v-col>
                  <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                </v-row>
                </a>
              </v-col>
              <v-col cols="4" md="3" lg="5"/>

              <v-col cols="1"/>
              <v-col cols="5" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="goauction">
                <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                  <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">Online Jewelry Auction</v-col>
                </v-row>
                <v-row align="center" justify="center" class="ma-0 pa-0">
                  <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">온라인 보석 경매</v-col>
                  <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                </v-row>
                </a>
              </v-col>
              <v-col cols="4" md="3" lg="5"/>

              <v-col cols="1"/>
              <v-col cols="5" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="gomoreinfo">
                  <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                    <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">More Info</v-col>
                  </v-row>
                  <v-row align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">더보기</v-col>
                    <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                  </v-row>
                </a>
              </v-col>
              <v-col cols="5" md="4" lg="5"/>

                

            </v-row>            
          </v-col>
          


          <!-- 모바일 해상도 -->
          <v-row class="mt-0 hidden-sm-and-up" align="center">
            
              <v-col cols="12" class="pa-0 pr-5 d-flex align-center">
                  <div style="margin-left:25%; width:82px; text-align: center;cursor: pointer;" 
                      class="d-flex justify-center" @click="type_select(4)">

                    
                    <v-img
                      src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/nd_home4.png"
                      height="82"
                      style="width:82px;border-radius:0px; border:2px white solid"
                      >
                    </v-img>
                    

                    <!-- 
                    <v-img
                      :src="require('@/assets/testimg1.png')"
                      height="82"
                      style="width:82px;border-radius:0px; border:2px white solid"
                      >
                    </v-img>
                    -->

                  </div>
                  <label 
                    style="text-align:left;color:white;font-size:18px;line-height:22px;margin-left:7px" 
                    @click="type_select(4)">천연 다이아몬드<br>Natural Diamond</label>
              </v-col>
              <!-- 
              <v-col cols="6" align="left" class="ma-0 pa-0 mt-5">
                  <p style="color:white;font-size:18px;line-height:22px" @click="type_select(4)">천연 다이아몬드<br>Natural Diamond</p>
              </v-col>
              -->
              
              <v-col cols="12" align="left" class="pa-0 pr-5 pt-2 d-flex align-center">
                <div style="margin-left:25%; width:82px; text-align: center;cursor: pointer;" 
                    class="d-flex flex-wrap justify-center" @click="type_select(5)">
                  
                  
                  <v-img
                    src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/ld_home4.png"
                    height="82"
                    style="width:82px;border-radius:0px; border:2px white solid"
                    >
                  </v-img>
                  
                  <!-- 
                    <v-img
                      :src="require('@/assets/testimg2.png')"
                      height="82"
                      style="width:82px;border-radius:0px; border:2px white solid"
                      >
                    </v-img>
                  -->
                </div>
                <label 
                  style="text-align:left;color:white;font-size:18px;line-height:18px;margin-left:7px" 
                  @click="type_select(5)">팬시 컬러<br>렙-그로운 다이아몬드<br>Fancy Color<br>Lab-Grown Diamond</label>
              </v-col>
              <!-- 
              <v-col cols="6" align="left" class="pa-0 mt-5">
                <p style="color:white;font-size:18px;line-height:22px" @click="type_select(5)">합성다이아몬드<br>Lab-Grown Diamond</p>
              </v-col>
              -->

              <v-col cols="12" align="left" class="pa-0 pr-5 pt-2 d-flex align-center">
                <div style="margin-left:25%; width:82px; text-align: center;cursor: pointer;" 
                    class="d-flex flex-wrap justify-center" @click="type_select(1)">
                  
                  <v-img
                    src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/gem_home5.png"
                    height="82"
                    style="width:82px;border-radius:0px; border:2px white solid"
                    >
                  </v-img>

                  <!-- 
                    <v-img
                      :src="require('@/assets/gem_home5.png')"
                      height="82"
                      style="width:82px;border-radius:0px; border:2px white solid"
                      >
                    </v-img>
                  -->

                </div>
                <label 
                  style="text-align:left;color:white;font-size:18px;line-height:22px;margin-left:7px" 
                  @click="type_select(1)">천연보석<br>GEM Stone</label>
              </v-col>
              <!-- 
              <v-col cols="6" align="left" class="pa-0 mt-5">
                <p style="color:white;font-size:18px;line-height:22px" @click="type_select(1)">천연보석<br>GEM Stone</p>
              </v-col>
              -->
            
          </v-row>

          <!-- 모바일 해상도 -->
          <v-row v-if="(this.$session.get('GRADE') >= 10 || this.$session.get('SUB_DAYS') >= 0) && editedItem != null" 
            class="mt-6 hidden-sm-and-up justify-start" 
            dense 
            >
            <label class="pl-6" style="color:white;font-size:18px;">RAPAPORT :&nbsp;</label>
            <label style="color:white;font-size:18px;" @click="showImage_pdf_popup(editedItem.RAPA_ROUNDS)">Rounds</label>
            <label style="color:white;font-size:18px;">&nbsp;/&nbsp;</label>
            <label style="color:white;font-size:18px;" @click="showImage_pdf_popup(editedItem.RAPA_PEARS)">Pears</label>
          </v-row>

          <!-- 모바일 해상도 -->
          <v-row class="hidden-sm-and-up" 
            :class="(this.$session.get('GRADE') >= 10 || this.$session.get('SUB_DAYS') >= 0) && editedItem != null ? 'mt-2' : 'mt-12'"
            dense>
              
              <v-col cols="4" align="right" class="mt-2">
                <div style="width:82px; text-align: center;cursor: pointer;" 
                    class="d-flex flex-wrap justify-center" @click="type_select(2)">
                  <v-img
                    src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/jew_home2.png"
                    height="82"
                    style="width:82px;border-radius:0px; border:2px white solid"
                    >
                  </v-img>
                  <!--   
                  <v-img
                    src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/jew_home1.png"
                    height="82"
                    style="width:82px;border-radius:0px; border:2px white solid"
                    >
                  </v-img>
                  -->
                </div>
              </v-col>
              <v-col cols="3" align="left" class="pa-0 mt-7">
                <p style="padding-left:1px;padding-top:2px;color:white;font-size:18px;line-height:24px" @click="type_select(2)">주얼리<br>JEWELRY</p>
              </v-col>

              <v-col cols="4" class="pr-5 mb-0">
                <div style="width:140px; text-align: center;cursor: pointer;" 
                    class="d-flex flex-wrap justify-center" @click="govivid">
                    <v-img
                      src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/vivid_home.png"
                      >
                    </v-img>
                </div>

                <div style="width:140px;height:26px;background:rgba(43, 43, 43, 1.0); border-radius:0px;border:1px white solid" class="mt-2 text-center">
                  <a @click="gofindshop">
                    <label style="color:white;margin-bottom:2px;margin-top:2px" class="btn_text_mbsmall2">내 주변 소매점 찾기</label>
                  </a>
                </div>
                
              </v-col>
              
          </v-row>
          
          <!-- 모바일 해상도 -->
          <v-row class="mt-5 mb-1 hidden-sm-and-up">
            <td width="10%"></td>
            <td width="90%" style="max-width:110px;margin-left:5px">
              <div @click="gosalereq" class="d-flex justify-center align-center" style="width:120px;height:38px;background:rgba(43, 43, 43, 1.0); border-radius:0px;border:1px white solid">
                  <label style="color:white;margin-left:7px;margin-right:7px;margin-bottom:2px;margin-top:2px" class="btn_text_mbsmall2">
                    내 다이아몬드 보석<br>판매 가격 알아보기
                  </label>
              </div>
            </td>
          </v-row>
          <v-row class="mt-4 hidden-sm-and-up">
            <td width="10%"></td>
            <td width="90%" style="max-width:110px;margin-left:5px">
              <div @click="goauction" class="d-flex justify-center align-center" style="width:120px;height:26px;background:rgba(43, 43, 43, 1.0); border-radius:0px;border:1px white solid">
                  <label style="color:white;margin-left:7px;margin-right:7px;margin-bottom:2px;margin-top:2px" class="btn_text_mbsmall2">
                    온라인 보석 경매
                  </label>
              </div>
            </td>
          </v-row>
          
          <v-row class="mt-4 mb-1 hidden-sm-and-up justify-center">
            <td width="50%" style="max-width:110px;">
              <div @click="gomoreinfo" class="d-flex justify-center align-center" style="width:120px;height:26px;background:rgba(43, 43, 43, 1.0); border-radius:0px;border:1px white solid">
                  <label style="color:white;margin-left:7px;margin-right:7px;margin-bottom:2px;margin-top:2px" class="btn_text_mbsmall2">
                    더 보 기
                  </label>
              </div>
            </td>
          </v-row>

        </v-row>

      </v-col>
    </v-row>


    <!-- 시범운영중 텍스트 -->
    <!-- 
    <div class="hidden-sm-and-up" style="font-style: italic;color:white;font-size:28px;line-height:28px;position:absolute;background-color:black;border: 3px solid white;top:80px;right:17px;writing-mode: vertical-rl;text-orientation: mixed;border-radius:10px;padding:20px 5px 20px;">
      <div class="text-left" style="color:white;margin-bottom:50px">Welcome to KDGE.</div><div class="text-right" style="color:white">Coming Soon!</div>
    </div>

    <div class="hidden-xs-only" style="font-style: italic;color:white;font-size:30px;line-height:32px;position:absolute;background-color:black;border: 3px solid white;top:80px;right:100px;border-radius:10px;font-weight:bold;padding:10px 15px 10px">
      Welcome to KDGE.<br>Coming Soon!
    </div>
    -->


    <!-- </div> -->
    </div>

    <!-- 
    <div class="circle-button"
      style="position:fixed;right:20px;bottom:60px;"   
      @click="openchat">
    </div>
    -->

  </v-container>
</template>
<script>

export default {

    mounted() {
      this.loadpage();
    },

    data: () => ({
      nextpage:'',
      login_dialog : false,
      editedItem:null,
    }),

    created(){

      //이벤트 수신 - 라파포트 갱신용
      this.$EventBus.$on('reloadrapa', this.loadpage);

    },

    beforeDestroy(){
      this.$EventBus.$off('reloadrapa');
    },

    beforeRouteLeave(to, from, next){
      console.log("beforeRouteLeave");
      if(this.$refs.pdfinfodown.pdf_dialog){
        this.$refs.pdfinfodown.close();
        next(false);
      }else{
        next();
      }
    },

    methods: {


      loadpage(){

        //소비자면 안됨.
        if(this.$session.get("GRADE") < 5){
          return;
        }

        console.log(this.$session.get("GRADE"));
        console.log(this.$session.get("SUB_DAYS"));

        
        this.$http.post(this.$host+'/RapaPdf',{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.editedItem = result.data.resultData[0];
              this.$forceUpdate();
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });        

      },      

      openchat(){
        window.ChannelIO('showMessenger');
      },

      type_select(type_index){
        this.PRODUCT_CLASS = type_index;
        if(type_index == 4){
          //천연
          this.PRODUCT_CLASS = 0;
          this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, dtype:0, vivid:0, isquick:0}});
        }else if(type_index == 5){
          //합성
          this.PRODUCT_CLASS = 0;
          this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, dtype:1, vivid:0, isquick:0}});
        }else{
          this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, vivid:0, isquick:0}});
        }
        //this.$router.push({path:"SearchOption", query:{code:this.PRODUCT_CLASS}});
        
        //this.search_run();
      },      
      
      goProductList(){
        this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1}});
      },

      goNotice(){
        this.$router.push({path:"/Notice", query:{stype:0}});
      },

      govivid(){
        //다이아몬드 디폴트로 비비드 제품 표시
        this.PRODUCT_CLASS = 0;
        this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, vivid:1, dtype:0}}); //비비드는 천연만
        //this.$router.push("/VividDiamond");
      },

      gocraft(){
        this.PRODUCT_CLASS = 3;
        this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, vivid:0, dtype:0, isquick:0}}); 
      },

      gofindshop(){
        this.$router.push("/FindShop");
      },

      gohowmuch(){
        this.$router.push("/UserSaleReq");
      },

      gomoreinfo(){
        this.$router.push("/MoreInfo");
      },

      goauction(){
        
        this.$fire({
          html: "This feature is being prepared.<br><br>준비중인 기능입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });

      },

      gosalereq(){
        if(this.$session.get("GRADE") >= 1){
          //판매요청으로 이동
          this.$router.push("/UserSaleReq");
        }else{
          //로그인 유도
          this.nextpage = "UserSaleReq";
          this.login_dialog = true;
        }
        
      },

      goLogin(){
        this.$router.push({path:"/Login", query:{next:this.nextpage}});
      },
      
      goJoin(){
        this.$router.push({path:"/Join"});
      },

      goEvent(){
        this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, vivid:0, stype:1}});
      },

      //여기서 PDF의 라파포트 시세 확인
      showImage_pdf_popup(url){
        this.$refs.pdfinfodown.geturl(url);
      },         

    },



}
</script>


<style scoped>

.imgtopbg{
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    min-height:1000px;
    height: 100%;
    width: 100%;
}

.imgtopbgmb{
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}


.imgtopbg2{
    background-image: url('https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/main_diamond_ring4_2.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0%;
    bottom: 0%;
    max-width: 1080px;
    max-height: 578px;
    top: 50vh;
    width: 60vw;
    height: 578px;
}

/*background-image: url('@/assets/main_diamond_ring3.png');*/
.imgtopbgmb2{
    background-image: url('https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/main_diamond_ring5.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 60vw;
    height: 146px;
    max-width: 264px;
    max-height: 146px;
}

.mobilemenupos{
    position: absolute;
    bottom: 0;
}


/*
.imgtopbg{
    background-image: url('@/assets/main_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    min-height:1000px;
    height: 100%;
    width: 100%;
}

.imgtopbgmb{
    background-image: url('@/assets/main_bg_mb.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
*/

.imgblack{
  opacity: 0.85;
  background-color: black;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background-attachment: fixed;
  min-height: 1000px;
  height: 100%;
  width: 100%;
}


.imgblackmb{
  opacity: 0.85;
  background-color: black;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background-attachment: fixed;
}

.black_btn_bg{
  background:black;
  height:107px
}
.black_btn_bg:hover{
  background:blue;
  height:107px
}



.main_text{
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  letter-spacing:0px !important;
}

.btn_text_small{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.btn_text_large{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: -0.05em;
}

.btn_text_mbsmall{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  white-space: nowrap;
}
.btn_text_mbsmall2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
}

.btn_text_mblarge{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: -0.05em;
  white-space: nowrap;
}

.imgscaleup{
  overflow: hidden;
  transform:scale(1.2);
  transition: transform 1s;
}


.dialog_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #090808;
  line-height: 26px;
}

.dialog_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:black;
}


</style>