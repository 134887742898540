<template>
<v-container fluid class="container_bg">


    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000FF" style="z-index:9999"
        v-model="notice_dialog" max-width="700"
        >
        <v-card class="pa-0 ma-0" style="overflow:hidden">

          <v-card-text class="ma-0 pa-0" style="min-height:200px">
            
            <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/noticehelp.jpg"/>

          </v-card-text>

          <v-card-actions style="background:black">

            <v-row align="center" justify="center" class="pt-1" style="height:60px">
              <v-col cols="1" class="ma-0 pa-0" align="end" align-self="center">
                <input type="checkbox" style="accent-color: #ffffff" :checked="agree1" @change="clickagree1">
              </v-col>
              <v-col cols="7" class="ma-0 pa-0 ml-2" align="start" align-self="center">
                <a @click="clickagree1" style="color:white"> 다시 열지 않기<br>Don't reopen</a>
                <!-- 
                <v-checkbox class="custom-outline-color" style="background:black;height:34px" color="white" v-model="checkbox" dense>
                  <template v-slot:label>
                    <label style="color:white">오늘은 그만 보기</label>
                  </template>
                </v-checkbox>                
                -->
              </v-col>
              <v-spacer/>
              <v-col cols="2" class="ma-0 pa-0 mr-3" align="center" align-self="center">
                <a style="color:white" @click="closenotice">닫기<br>Close</a>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

      </v-dialog>      
    </v-row>

    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000FF" style="z-index:9999"
        v-model="check_dialog" max-width="700"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        >
        <v-card class="pa-0 ma-0" style="overflow:hidden">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center" style="height:70px;background:black">
              <v-col cols="2">
              </v-col>
              <v-col cols="8"  align="center" class="d-flex align-center justify-center">
                <div style="color:white;font-size:16px;line-height:20px">Check Before Use<br>이용 전 확인 안내</div>
              </v-col>
              <v-col cols="2">
                <v-btn icon @click="closecheck"><v-icon color="white">mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pa-0" style="min-height:200px">

            <v-row dense align="center" justify="center" class="mt-0 ma-0">
              <v-col cols="11" md="11" align="start" class="pa-0 ma-0">
                <v-tabs
                  fixed-tabs
                  background-color="white"
                  color="purple"
                  flat
                  v-model="tab"
                  >
                  <v-tab :key="0">
                    한국어
                  </v-tab>
                  <v-tab :key="1">
                    ENGLISH
                  </v-tab>
                </v-tabs>
                
                  
                <v-tabs-items v-model="tab" class="mt-2">
                    <v-tab-item :key="0">
                      <v-row justify="center" dense>
                        <v-col>
                        <p class="text-md-body-2 text-subtitle-2 text-left mt-3 ma-0 pa-0 pl-md-10 pl-1 " style="color:black">
                          
                          <span style="font-size:18px;color:black;font-weight:bold">이용 전 확인 안내</span><br><br>
                          
                          <span style="font-size:15px;color:black;font-weight:bold">kDiaGem App 소개</span><br>
                          kDiaGem App은 KDGE(한국다이아몬드보석거래소)가 운영하는 신개념의 글로벌 다이아몬드와 보석 거래플랫폼입니다. 또한 구매요청 하는 모든 상품은 KDGE가 책임지고 직수입하여 공급합니다. 따라서 KDGE가 모든 상품에 대한 품질을 보증하고 불량품 등 하자발생에 대해  전적으로 책임집니다.<br><br>
                          
                          1. KDGE와 제휴를 맺은 전 세계의 다이아몬드 및 보석 회사들이 약 3000억 원 이상의 다양한 제품을 전시하고 있습니다.<br>
                          2. 고객이 주문하면 KDGE가 직수입하고 중간 마진이나 불필요한 수수료 없이 공급합니다.<br>
                          3. 따라서 KDGE가 보증하는 가장 좋은 품질의 상품을 최저 가격으로 구매 할 수 있습니다.<br>
                          4. 지금껏 그래 왔듯이 천연 광물은 시간이 지나면 그 가치가 상승하고 국제가격이 오르기 때문에 천연 다이아몬드 또는 보석을 구매 후 오랫동안 착용하고 세월이 지날수록 더 많은 가치가 높아지는 안전한 미래 투자가치를 지니고 있습니다.<br>
                          5. 또한 kDiaGem App에서 구매한 상품은 언제든지  KDGE가 재매입을 보장합니다.<br>
                          6. 특히 GIA Certificate 천연 다이아몬드를 주문할 경우 즉시 수입하여  평가등급 세분화 기준으로 엄격한 재 감정을 실시합니다.<br>
                          7. KDGE는 평가등급 세분화 기준을 통과한 다이아몬드만을 엄선하여 VIVID DIAMOND Certificate를 발행하여 기존 GIA Certificate를 함께 첨부하여 납품합니다.<br><br>

                          
                          <template v-if="$vuetify.breakpoint.smAndDown">
                            <v-btn class="pa-0" text color="red" @click="govivid"><v-icon class="mr-1" style="color:black">mdi-magnify-expand</v-icon>VIVID DIAMOND 설명 및 감정서 보기</v-btn><br><br>
                          </template>
                          <template v-else>
                            <v-btn icon color="black" @click="govivid"><v-icon>mdi-magnify-expand</v-icon></v-btn><v-btn text color="red" @click="govivid">VIVID DIAMOND 설명 및 감정서 보기</v-btn><br><br>
                          </template>

                          <span style="font-size:15px;color:black;font-weight:bold">거래 방법</span><br>
                          1. kDiaGem App의 천연 다이아몬드 / 렙-그로운 다이아몬드 중에서 원하는 품목을 선택 후 상단에 있는 옵션선택검색을 선택합니다.<br>
                          2. 검색 창에 있는 옵션들 중 필요한 (크기, 가격, 컬러, 컷팅, 투명도, 연마, 형광성, 모양, 감정기관 등등) 등급 조건을 선택하여 하단 우측 적용을 누르면 원하는 다이아몬드들을 찾을 수 있습니다.<br>
                          3. 단순한 제품 문의 등의 상담은 화면의 하단에 있는 회원 가입이 필요 없는 채팅 상담(채널톡)으로 실시간 소통하실 수 있습니다.<br>
                          4. 구매의사를 가지고 구매 문의를 하실 경우에는 회원가입을 하신 다음 구매문의(자동 이메일 연결)을 요청하시면 친절히 상담해 드립니다.<br>
                          5. 구매 문의한 상품은 KDGE가 철저하게 상품의 품질 등을 확인 한 후 문제가 있을 경우에는 책임지고 보증 할 수 있는 품질의 대체 상품을 추천합니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">결제 방법</span><br>
                          1.  불필요한 수수료를 없애고 최저가로 공급을 하기 위해 모든 상품의 대금 결제는 본사 통장 거래로만 가능합니다.<br>
                          2. 대금결제
                          <v-col cols="11">
                            (1)상품대금 100% 전액을 지불할 경우 총 상품가격의 2% DC 혜택을 드립니다.<br>
                            (2)10%의 계약금(구매보증금)을 지불하고 인수하면서 잔액 90%를 지불할 수 있습니다. <br>
                          </v-col>
                          3. 모든 상품은 정식 수입을 하기 때문에 부가세 10%가 포함되어 있으며, 현금영수증 또는 세금계산서를 발행합니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">납품 절차</span><br>
                          1. 납품 기간은 상품대금 전액 또는 계약금(10%)을 KDGE 통장으로 입금한 날로부터 7일~14일이 소요됩니다. <br>
                          2. 납품 방법은 주문한 소매상에서 찾으실 수 있고, 고객이 지정하는 장소로 KDGE 직원이 직접 납품해 드립니다.<br>
                          3. 또한 고객이 희망할 경우 본사를 방문하여 직접 확인 후 인수하실 수도 있습니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">환매 기준</span><br>
                          1. KDGE는 판매한 다이아몬드 또는 보석 및 주얼리에 대해 구매자가 판매를 원할 경우 다음 환매 기준에 따라 재매입을 보장합니다.<br>
                          <v-col cols="11">
                          1) DIAMOND 및 보석 나석은 환매 당시 판매가의 80%에 재매입 (VAT 포함) <br>
                          2) DIAMOND 및 고가 보석이 세팅된 주얼리는 판매가의 70%에 재매입 (VAT 포함) <br>
                          3) 순금주얼리 : 순금 실 중량 × 국제시세<br>
                          4) 준보석 및 패션주얼리 : 감정평가 후 결정<br>
                          </v-col>
                          2. 환매하려는 상품이 구매자의 부주의로 인하여 크게 파손되었거나 KDGE가 판매한 상품이 아닌 경우에는 환매 기준이 적용되지 않습니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">소비자보유 다이아몬드 및 보석 판매 또는 교환요청</span><br>
                          1. 소비자가 보유한 다이아몬드나 보석을 판매하고자 하거나  VIVID DIAMOND로 교환을 원할 경우 가장 좋은 조건의 국제가격을 적용해 드립니다.<br>
                          2. 소비자가 보유한 다이아몬드 또는 보석의 판매를 원하시면 회원가입 후 소비자판매요청을 이용하시면 KDGE 담당자가 친절하게 안내해 드립니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">구매 취소</span><br>
                          1. 구매자는 상품대금이나 10%의 계약금(구매 보증금)을 KDGE 본사의 통장으로 송금하기 전까지는 언제든지 구매를 취소할 수 있습니다.<br>
                          2. 주문하신 상품이 아니거나 상품에 하자가 있는 경우에는 아무 조건 없이 취소할 수 있으며 100% 환불해 드립니다.<br>
                          3. 그러나 상품 주문 후 천재지변이 아닌 단순 변심에 의해 구매 취소할 경우 계약금(구매보증금) 또는 상품 가격의 10%는 환불해 드리지 않습니다. <br>
                          4. 그 이유는 해외 수입에 따른 비용과 감정 수수료 및 부가세 등의 비용이 발생하기 때문입니다.<br><br>

                          <span style="font-size:15px;color:black;font-weight:bold">주의사항</span><br>
                          1. 다이아몬드 또는 보석에 첨부되어 있는 이미지(사진)는 실물과 다를 수 있습니다.<br>
                          2. 따라서 반드시 첨부된 다이아몬드 감정서를 참고하여 KDGE에 문의하시면 친절하게 안내 해 드립니다.<br>
                          3. 궁금한 점은 언제든지 회원가입이 필요 없는 채팅 상담(채널톡)을 이용해 실시간 소통하실 수 있습니다.<br>
                          4. 데이터베이스권 침해 금지<br>본 kDiaGem 웹사이트 및 앱에 게시된 사진, 링크, 동영상을 포함하는 모든 정보에 대해 수집, 가공, 복제를 할 수 없습니다. 이를 어길 경우 저작권법에 의거하여 처벌받게 되며, 그 행위자는 본사의 손해배상 청구를 전적으로 책임져야 함을 고지합니다.<br><br>
                        </p>
                        </v-col>
                      </v-row>
                      <v-row dense align="center" justify="center" class="mt-3 ma-0">
                        <v-col align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 " style="color:#512da8">질문 채팅 상담(채널톡)</p>
                        </v-col>
                      </v-row>
                      <v-row dense align="center" justify="center" class="ma-0 pa-0 mb-5">
                        <v-col align="start" class="pa-0 ma-0">
                          <p class="text-md-body-2 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 " style="color:black">
                            전화 +82-2-765-0701<br>
                            *kdge1000@gmail.com            
                          </p>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :key="1">
                      <v-row justify="center" dense>
                        <v-col>
                        <p class="text-md-body-2 text-subtitle-2 text-left mt-3 ma-0 pa-0 pl-md-10 pl-1 " style="color:black">

                        <span style="font-size:18px;color:black;font-weight:bold">Check Before Use</span><br><br>
                        
                        <span style="font-size:15px;color:black;font-weight:bold">About kDiaGem App</span><br>
                        kDiaGem App is a new concept global diamond & gem trading platform operated by KDGE (Korea DIAMOND GEM Exchange). In addition, all products requested for purchase are directly imported and supplied by KDGE. Therefore, KDGE guarantees the quality of all products and takes full responsibility for defects such as defective products.<br><br>

                        1. Diamond and gemstone companies around the world that have partnered with KDGE are exhibiting various products worth about KRW 300 billion.<br>
                        2. When a customer places an order, KDGE imports directly and supplies without intermediate margins or unnecessary fees.<br>
                        3. Therefore, you can purchase the best quality products guaranteed by KDGE at the lowest price.<br>
                        4. As has always been the case, since natural minerals increase in value and international prices over time, they are worn for a long time after purchasing natural diamonds or gemstones, and have a safe future investment value that increases in value as time goes by.<br>
                        5. In addition, KDGE guarantees the repurchase of products purchased from the kDiaGem App at any time.<br>
                        6. In particular, when ordering GIA Certificate natural diamonds, we import them immediately and conduct strict re-evaluation based on evaluation grade subdivision.<br>
                        7. KDGE carefully selects only diamonds that pass the evaluation grade segmentation criteria, issues a VIVID DIAMOND Certificate, and attaches the existing GIA Certificate to the delivery.<br><br>
                        
                        <template v-if="$vuetify.breakpoint.smAndDown">
                          <v-btn class="pa-0" text color="red" @click="govivid"><v-icon class="mr-1" style="color:black">mdi-magnify-expand</v-icon>View VIVID DIAMOND description<br>and appraisal certificate</v-btn><br><br>
                        </template>
                        <template v-else>
                          <v-btn icon color="black" @click="govivid"><v-icon>mdi-magnify-expand</v-icon></v-btn><v-btn text color="red" @click="govivid">View VIVID DIAMOND description and appraisal certificate</v-btn><br><br>
                        </template>
                        <span style="font-size:15px;color:black;font-weight:bold">Transaction Method</span><br>
                        1. After selecting the desired item among natural diamonds / lab-grown diamonds in the kDiaGem App, select SEARCH OPTION SETTING at the top.<br>
                        2. Select the grade condition you need (size, price, color, cut, clarity, polish, fluorescence, shape, laboratory, etc.) from the options in the search window and click Apply at the bottom right to find the diamonds you want.<br>
                        3. For consultations such as simple product inquiries, you can communicate in real time with the Chat Inquiry(channel.io) at the bottom of the screen that does not require membership registration.<br>
                        4. If you have a purchase intention and make a purchase inquiry, please sign up as a member and then request a purchase inquiry (automatic e-mail connection) and we will kindly consult you.<br>
                        5. For the product you inquired about, KDGE thoroughly checks the quality of the product, and if there is a problem, it recommends a replacement product with a quality that can be responsibly guaranteed.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Payment Method</span><br>
                        1. In order to eliminate unnecessary fees and supply at the lowest price, payment for all products can only be made through the head office's bank account transaction.<br>
                        2. Payment
                        <v-col cols="11">
                          (1) If you pay 100% of the product price in full, you will receive a 2% DC benefit of the total product price.<br>
                          (2) You can pay 10% down payment (purchase deposit) and pay the remaining 90% upon takeover.<br>
                        </v-col>
                        3. All products are formally imported, so 10% VAT is included, and cash receipts or tax invoices are issued.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Delivery Procedure</span><br>
                        1. The delivery period takes 7 to 14 days from the date the full product price or down payment (10%) is deposited into KDGE bank account.<br>
                        2. The delivery method can be found at the retailer where the order was placed, and KDGE staff will deliver directly to the location designated by the customer.<br>
                        3. In addition, if the customer wishes, he or she can visit the head office and directly check and take over.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Repurchase Standards</span><br>
                        1. KDGE guarantees the repurchase of sold diamonds, gemstones or jewelry according to the following repurchase standards if the buyer wants to sell them.
                        <v-col cols="11">
                        1) DIAMOND and polished gemstones are repurchased at 80% of the selling price at the time of redemption (VAT included)<br>
                        2) Repurchase of jewelry set with DIAMOND and expensive gemstones at 70% of the selling price (VAT included)<br>
                        3) Pure gold jewelry: Actual weight of pure gold × International market price<br>
                        4) Semi-precious stones and fashion jewelry: Decided after appraisal<br>
                        </v-col>
                        2. If the product to be redeemed is significantly damaged due to the buyer's negligence or is not a product sold by KDGE, the repurchase standard does not apply.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Requesting the sale or exchange of diamonds and gemstones owned by consumers</span><br><br>
                        1. If you want to sell your diamonds and gemstones, or exchange them for VIVID DIAMOND, we will apply the best international price.<br>
                        2. If you want to sell diamonds or gemstones owned by consumers, please use the consumer sales request after signing up, and a KDGE representative will kindly guide you.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Cancel Purchase</span><br>
                        1. The purchaser may cancel the purchase at any time until the product price or 10% down payment (purchase deposit) is remitted to the bank account of KDGE headquarters.<br>
                        2. If the product is not what you ordered or if the product is defective, you can cancel without any conditions and we will give you a 100% refund.<br>
                        3. However, if the purchase is canceled due to a simple change of mind, not a natural disaster after ordering the product, the down payment (purchase deposit) or 10% of the product price will not be refunded.<br>
                        4. The reason is that there are costs related to overseas imports, appraisal fees, and VAT.<br><br>

                        <span style="font-size:15px;color:black;font-weight:bold">Caution</span><br>
                        1. Images (photos) attached to diamonds or gemstones may differ from the actual product.<br>
                        2. Therefore, if you make an inquiry to KDGE referring to the attached diamond appraisal certificate, we will kindly guide you.<br>
                        3. If you have any questions, you can communicate in real time using a Chat Inquiry(channel.io) that does not require membership registration at any time.<br>
                        4. Prohibition of Infringement of Database Rights<br>All information including photos, links, and videos posted on this kDiaGem  website and app cannot be collected, processed, or reproduced. If you violate this, you will be punished according to the copyright law, and we inform you that the violator must be fully responsible for claims for damages from the head office.<br><br>
                        </p>
                        </v-col>
                      </v-row>

                      <v-row dense align="center" justify="center" class="mt-3 ma-0">
                        <v-col align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 " style="color:#512da8">Question: Chat Inquiry(channel.io)</p>
                        </v-col>
                      </v-row>
                      <v-row dense align="center" justify="center" class="ma-0 pa-0 mb-5">
                        <v-col align="start" class="pa-0 ma-0">
                          <p class="text-md-body-2 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 " style="color:black">
                            Phone +82-2-765-0701<br>
                            *kdge1000@gmail.com            
                          </p>
                        </v-col>
                      </v-row>

                    </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
            
          </v-card-text>

          <v-card-actions style="background:black">
            <v-row align="center" justify="center" class="pt-1" style="height:60px">
              <v-col cols="6" class="ma-0 pa-0 mr-3" align="center" align-self="center"  @click="closecheck">
                <a style="color:white">닫기<br>Close</a>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

      </v-dialog>      
    </v-row>    

    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000FF" v-model="ks_dialog" 
        fullscreen
        >
        <v-card class="pa-0 ma-0">

          <v-card-text class="ma-0 pa-1" style="overflow-x: hidden;">

            <v-row justify="center" style="color:black">
              <v-col cols="11" md="8" align="center" class="mt-5 mt-md-15">
                <div class="mt-5 mt-md-15" 
                    style="color:black;font-weight:bold"
                    :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
                    >KS 규격 감정서 설명</div>

                <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS 규격이란</div>
                <div class="mt-0 notosanskr_font" style="text-align:left;">‘KS’는 ‘Korean Industrial Standards’의 약자로 산업표준 심의회의 조사, 심의를 거쳐 정부가 제정한 대한민국의 국가 표준입니다.</div>

                <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS 다이아몬드 감정 규격</div>
                <div class="mt-0 notosanskr_font" style="text-align:left;">연마된 다이아몬드 감정에 대한 KS 규격은 KS D 2371-1 용어의 정의 및 분류, KS D 2371-2 검사 방법에 명기되어 있으며, 국제규격(ISO)을 기초로 제정되었습니다.</div>

                <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS 다이아몬드 검사 방법</div>
                <div class="mt-0 notosanskr_font" style="text-align:left;">불규칙한 다이아몬드 감정 결과에 의한 소비자 피해를 예방하고, 소매상과 소비자와의 분쟁이 발생하지 않도록 대한민국의 한국산업표준(KS)을 적용하여 감정을 하는 국제표준 시스템입니다.</div>

                <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS 다이아몬드 감정 결과</div>
                <div class="mt-0 notosanskr_font" style="text-align:left;">다이아몬드 위원회가 지정한 3곳의 KS 규격 감정기관들(미래보석감정원, 서울보석감정원, 한미보석감정원)이 공동으로 엄격하게 감정한 결과는 국제 기준에 부합한 등급임을 확인합니다.</div>

                <div class="mt-5 mt-md-15" 
                    style="color:black;font-weight:bold"
                    :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
                    >Description of KS Certification</div>
                <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  What is KS Standard?</div>
                <div class="mt-0 inter_font" style="text-align:left;">‘KS’ is an abbreviation for Korean Industrial Standards and is the national standard of Korea established by the government after investigation and deliberation by the Industrial Standards Council.</div>

                <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS Diamond Appraisal Standard</div>
                <div class="mt-0 inter_font" style="text-align:left;">The KS standard for polished diamond appraisal is specified in the definition and classification of KS D 2371-1 terms and KS D 2371-2 inspection methods, and was established based on the International Standard (ISO).</div>

                <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS Diamond Inspection Method</div>
                <div class="mt-0 inter_font" style="text-align:left;">It is an international standard system for appraisal by applying Korean Industrial Standards (KS) to prevent consumer damage caused by irregular diamond appraisal results and to prevent disputes between retailers and consumers.</div>

                <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
                  KS Diamond Appraisal Result</div>
                <div class="mt-0 inter_font" style="text-align:left;">The results of rigorous evaluation jointly by three KS standard appraisal agencies designated by the Diamond Commission (Mirae Gem Laboratory Co., Ltd.,  Gemological Institute Of Seoul, Hanmi Gemological Institute & Lab) confirm that the grade meets international standards.</div>

              </v-col>
            </v-row>

            <v-row justify="center" style="color:black">

                <v-col cols="12" md="8">

                  <div class="mt-5 mt-md-10" 
                    style="color:black;font-weight:bold;text-align:center"
                    :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
                    >KS 규격 감정서 표준 약관</div>

                    <ol class="ml-2 mr-2 mt-5 mt-md-5 notosanskr_font" type="1" start="1">
                        <li>본 감정서는 의뢰된 감정서의 감정 당시 상태를 (단체인증 제도에서 정한) 4C 시스템에 의하여 등급화 한 것입니다.</li>
                        <li>중량 단위는 메트릭 캐럿 (Metric Carat)으로 표기하며, 전자저울로 측정하여 소수점 셋째 자리에서 8 이하는 버리고 9에서만 올림 하여 소수점 둘째 자리까지 표기한 것입니다.</li>
                        <li>의뢰되는 다이아몬드는 나석 상태를 원칙으로 하며, 세팅된 상태에서 감정된 경우에는 감정 결과에 오차가 생길 수 있습니다.</li>
                        <li>본 감정서의 감정 내용은 감정 당시의 기술력을 바탕으로 한 것이므로 미래의 발전된 기술 및 감별 장비의 발전으로 인하여 감정 및 감별 결과에 차이가 생길 수 있습니다.</li>
                        <li>다음의 경우에는 감정서가 무효화됩니다.<br>(1) 감정서 내용의 변조, 가필 또는 수정된 경우<br>(2) 감정 후 해당 다이아몬드를 가공 또는 변형시킨 경우</li>
                        <li>복사된 감정서는 원본의 효력을 대신할 수 없습니다.</li>
                        <li>본 감정원은 감정 의뢰인의 동의 없이 비파괴 검사를 통하여 의뢰된 다이아몬드의 천연, 합성 및 인공 처리 등에 대한 실험을 할 수 있습니다.</li>
                        <li>수집된 정보 및 모든 자료는 다이아몬드 산업의 발전을 위하여 사용될 수 있으며 지식재산권은 본 감정원에 귀속됩니다.</li>
                        <li>본 감정서는 가격 평가서가 아닌 단지 의뢰된 다이아몬드의 특징과 상태에 대한 내용을 표기한 것으로 그 내용에 관해서는 신뢰할 수 있는 전문가 또는 판매점의 도움을 받을 것을 권합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>

                  <div class="mt-5 mt-md-10" 
                    style="color:black;font-weight:bold;text-align:center"
                    :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
                    >Terms and Conditions of KS Certification</div>
                    <ol class="ml-2 mr-2 mt-5 mt-md-5 inter_font" type="1" start="1">
                        <li>This certificate is a rating of the requested appraisal report's status at the time of appraisal by the 4C system (as prescribed by the collective certification system).</li>
                        <li>The unit of weight is expressed as a metric carat, and measured by an electronic scale, 8 or less from the third decimal place is discarded and raised only from 9 to the second decimal place.</li>
                        <li>In principle, the requested diamond is in a loose stone, and if it is evaluated while being set in a product, there may be errors in the evaluation result.</li>
                        <li>Since the appraisal contents of this certificate are based on the technical skills at the time of appraisal, there may be differences in the appraisal and discrimination results due to the development of technologies and discrimination equipment in the future.</li>
                        <li>The certificate will be invalidated in the following cases.<br>
                            (1) If the contents of the certificate are modified, falsified, or altered.<br>
                            (2) If the diamond is processed or modified after the evaluation.</li>
                        <li>A copied certificate cannot replace the validity of the original.</li>
                        <li>This Appraisal Board may conduct experiments on the natural, synthetic, and artificial treatment of the requested diamonds through non-destructive testing without the consent of the appraisal client.</li>
                        <li>The collected information and all data can be used for the development of the diamond industry, and intellectual property rights belong to this Appraisal Board.</li>
                        <li>This certificate is not a price assessment, but merely a description of the characteristics and condition of the requested diamond, and we recommend that you seek the help of a trusted expert or dealer.</li>
                    </ol>
                </v-col>
            </v-row>


          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="ks_dialog = false">Close(닫기)</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>     
    </v-row>

    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000FF" v-model="vs_dialog" 
        fullscreen
        >
        <v-card class="pa-0 ma-0">
          <v-card-text class="ma-0 pa-1" style="overflow-x: hidden;">
            <vividdiamond />
          </v-card-text>
          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="vs_dialog = false">Close(닫기)</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>

      </v-dialog>      
    </v-row>

    <!-- 제품 검색 옵션 -->
    <v-row align="center"  justify="center" >
      
      <v-dialog scrollable persistent overlay-color="#000000FF"  v-model="search_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center" style="height:70px;background:black">
            <v-col cols="2">
            </v-col>
            <v-col cols="8"  align="center" class="d-flex align-center justify-center">
              <div style="color:white;font-size:16px;line-height:20px"
                >Search Options 선택검색하기<br>
              {{ PRODUCT_CLASS == 0 ? this.dtype == 0 ? 'NATURAL DIAMOND' : 'LAB-GROWN DIAMOND' : PRODUCT_CLASS == 1 ? 'GEM Stone' : 'JEWELRY' }}
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="search_dialog = false"><v-icon color="white">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="scroll-container">

              <v-row align="center" justify="center" dense>
                <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                  <!-- 젬스톤 : 종류 -->
                  <template v-if="PRODUCT_CLASS == 1">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Type <span style="color:#512da8">보석종류</span></p>
                    </v-col>
                  </v-row>
                  
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex flex-wrap">

                      <v-btn-toggle
                        v-model="search_gemstone_type"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in JEWELRYKINDLIST">
                        <v-btn :width="$vuetify.breakpoint.smAndUp ? 140 : 80" 
                               :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               elevation="1" :key="item.IDX" class="ma-1" :value="item.IDX"
                               style="border: 2px solid black !important;border-radius:0px"
                               >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                          <div class="ma-0 pa-0" style="font-weight:bold;color:#202020;"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                            >
                            {{item.NAME_ENG}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                      
                    </v-col>
                  </v-row>
                  </template>

                  <!-- 다이아, 젬스톤 : 중량 -->
                  <template v-if="PRODUCT_CLASS == 0">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Carat <span style="color:#512da8">중량</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="ma-0 mt-md-3" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                      <inputweight @setprice="setminweight" :value="search_min_weight"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputweight @setprice="setmaxweight" :value="search_max_weight"/>
                    </v-col>
                    <!-- 
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <v-range-slider
                        v-model="search_range_weight"
                        :min="0.2"
                        :max="20"
                        :step="0.01"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        @input="setWeightRange"
                        hide-details dense
                        />
                    </v-col>
                    -->
                  </v-row>
                  </template>

                  <template v-if="PRODUCT_CLASS == 1">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Carat <span style="color:#512da8">중량</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-3" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                      <inputweight @setprice="setgemminweight" :value="search_gem_min_weight"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputweight @setprice="setgemmaxweight" :value="search_gem_max_weight"/>
                    </v-col>

                    <!-- 
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <v-range-slider
                        v-model="search_range_gemweight"
                        :min="0.2"
                        :max="30"
                        :step="0.01"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        @input="setGemWeightRange"
                        hide-details dense
                        />
                    </v-col>
                    -->
                  </v-row>
                  </template>

                  <template v-if="PRODUCT_CLASS == 2">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Jewelry Type <span style="color:#512da8">주얼리 종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-btn-toggle
                        class="d-flex flex-wrap justify-space-around mt-1 mb-1" 
                        color="deep-purple accent-3" tile 
                        v-model="search_jewely_type"
                        @change="changejewelrytype"
                        >
                        <template v-for="item in PRODUCTTYPELIST">
                        <v-btn  :color="search_jewely_type == item.IDX ? 'red' : 'black'"
                                :width="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                :height="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                elevation="1" :key="item.IDX" :value="item.IDX" 
                                style="margin-left:2px;margin-right:2px;border: 2px solid black !important;border-radius:0px" 
                                >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <!-- <img class="ma-0 pa-0 mt-1" :width="$vuetify.breakpoint.smAndUp ? 50 : 24" :src="item.IMG"/> -->
                          <div class="ma-0 pa-0 mt-1" style="font-weight:normal;color:white;white-space:pre-wrap;text-align:center;"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                            >
                            {{item.NAME_KOR}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-row>

                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-btn-toggle
                        class="d-flex flex-wrap  ma-0 pa-0" 
                        color="deep-purple accent-3" tile 
                        v-model="search_jewely_item" multiple
                        @change="changejewelryitem"
                        >
                        <template v-for="item in PRODUCTITEMLIST">
                        <v-btn  :width="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                :height="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                elevation="1" :key="item.IDX" :value="item.IDX" 
                                style="margin-left:2px;margin-right:2px;border: 2px solid black !important;border-radius:0px" 
                                >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0 mt-1" :width="$vuetify.breakpoint.smAndUp ? 50 : $vuetify.breakpoint.width/12" :src="item.IMG"/>
                          <div class="ma-0 pa-0 mt-1" style="font-weight:bold;color:#202020"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                            >
                            {{item.NAME_KOR}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-row>

                  </template>

                  <!-- 가격 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Price <span style="color:#512da8">가격</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">$</div>  
                      <inputprice @setprice="setminprice" :pricetype="0" :value="search_min_price"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputprice @setprice="setmaxprice" :pricetype="0" :value="search_max_price"/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">₩</div>  
                      <inputprice @setprice="setminprice2" :pricetype="1" :value="search_min_price2"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputprice @setprice="setmaxprice2" :pricetype="1" :value="search_max_price2"/>
                    </v-col>
                  </v-row>

                  <!-- 다이아몬드 -->
                  <template v-if="PRODUCT_CLASS == 0">
                  
                  <!-- 다이아몬드 타입 -->
                  <v-row v-if="showDiamondType" dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Diamond Type 다이아몬드 <span style="color:#512da8">구분</span></p>
                    </v-col>
                  </v-row>
                  <v-row v-if="showDiamondType" dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row v-if="showDiamondType" dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_diamondtype" 
                        multiple class="flex" color="red"
                        >
                        <template v-for="item in DIAMONDTYPELIST">
                          <v-btn width="50%" :color="search_diamondtype.includes(item.value)?'red':'black'"
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 35"
                                style="border: 2px solid black !important;border-radius:0px"
                                elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                            <div class="ma-0 pa-0" 
                              style="font-weight:bold;color:#ffffff;white-space: pre-wrap;text-transform: none"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px;letter-spacing :0.5px;'" 
                              >{{item.name}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>                                  

                  <!-- 컬러 -->
                  <v-row dense align="center" justify="center" class="mt-1 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Color<span style="color:#512da8">컬러</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->

                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">

                      <!-- range slider -->
                      <v-range-slider
                        :tick-labels="COLORSNAME"
                        v-model="search_color"
                        :min="0"
                        :max="10"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        thumb-size="25"
                        style="font-size:11px;"
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:12px;text-align:center;white-space: nowrap;">
                            {{ COLORSNAME[props.value] }}
                          </label>
                        </template>                        
                        </v-range-slider>
                    </v-col>
                  </v-row>

                  <!-- 투명도 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Clarity <span style="color:#512da8">투명도</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">

                      <v-range-slider
                        :tick-labels="TRANSNAMES"
                        v-model="search_transparency"
                        :min="0"
                        :max="11"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        thumb-size="30"
                        style="font-size:11px;"
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:12px;text-align:center;white-space: nowrap;">
                            {{ TRANSNAMES[props.value] }}
                          </label>
                        </template>                        
                        </v-range-slider>
                    </v-col>
                  </v-row>

                  <!-- 연마 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Cut <span style="color:#512da8">연마</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Cut Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important" >
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_total"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        :style="$vuetify.breakpoint.smAndDown ? 'width:70%' : ''"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Polish Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important">
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_polish"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        :style="$vuetify.breakpoint.smAndDown ? 'width:70%' : ''"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Symmetry Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important">
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_syme"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        :style="$vuetify.breakpoint.smAndDown ? 'width:70%' : ''"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>                  

                  <!-- 형광성 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Fluorescence <span style="color:#512da8">형광성</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_fluorescence"
                        :tick-labels="FLUNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ FLUNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  -->

                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_fluorescence"
                        multiple class="d-flex flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in FLUORESCENCELIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 85 : 85" 
                                :height="$vuetify.breakpoint.smAndUp ? 30 : 25" 
                                elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value"
                                style="border: 2px solid black !important;border-radius:0px"
                                >
                            <div class="ma-0 pa-0" style="font-family:initial;font-weight:bold;color:#202020;text-transform:none;"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                              >{{item.name}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>                  

                  <!-- 다이아몬드 감정서 -->
                  <v-row v-if="PRODUCT_CLASS == 0 && vivid == 0" dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="10" md="8" align="start"  class="d-flex ma-0 pa-0">
                      <div class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 text_searchdialog_subtitle" 
                         style="color:black;display:inline">Certificate <span style="color:#512da8">감정서</span></div>
                    </v-col>
                    <v-col cols="2" md="2" align="start"  class="d-flex justify-end ma-0 pa-0">
                      <v-btn x-small color="black" style="color:white" @click="vs_dialog = true">VIVID</v-btn>
                      <!-- <v-btn x-small color="black" class=" ml-1" style="color:white" @click="ks_dialog = true">KS</v-btn> -->
                    </v-col>

                  </v-row>
                  <v-row v-if="PRODUCT_CLASS == 0 && vivid == 0" dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row v-if="PRODUCT_CLASS == 0 && vivid == 0" dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_certi"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in DIACERTILIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 70 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                elevation="1" class="mr-1 mt-1" :key="item.IDX" :value="item.IDX"
                                style="border: 2px solid black !important;border-radius:0px"
                                >
                            <div class="ma-0 pa-0" style="font-family:initial;font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:11px'" 
                              >{{item.NAME}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>

                  <!-- 쉐입 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Diamond Shape <span style="color:#512da8">모양</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex flex-wrap">
                      <v-btn-toggle
                        v-model="search_shape" 
                        multiple class="d-flex flex-wrap" tile
                        color="deep-purple accent-3"
                        >
                        <template v-for="item in DIASHAPELIST">
                        <v-btn :width="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               elevation="1" :key="item.IDX" :value="item.IDX" class="ma-1" 
                               style="border: 2px solid black !important;border-radius:0px"
                               >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                          <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                            >
                            {{item.NAME_ENG}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>


                  <!-- 다이아몬드 컬러 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Colored Diamonds <span style="color:#512da8">컬러 다이아몬드</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" align-self="center" class="ma-0 pa-0 d-flex justify-center">
                      <v-btn-toggle
                        v-model="search_diamondcolor" tile
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in DIAMONDCOLORLIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                                style="border: 2px solid black !important;border-radius:0px"
                                elevation="1" :key="item.value" class="ma-1" :value="item.value">
                            <v-row class="d-flex flex-column align-center justify-center" dense>
                            <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                              >
                              {{item.name}}
                            </div>
                            </v-row>
                          </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>

                  </template>

                  <!-- 주얼리 -->
                  <template v-if="PRODUCT_CLASS == 2">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Name Search <span style="color:#512da8">보석이름 검색</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                        <input
                          dense single-line clearable
                          v-model="search_jw_name" 
                          color="#000000"
                          hide-details 
                          class="basicsearchstyle_gem"
                          />
                      </v-col>
                      <v-col cols="12" md="10" align="start" style="color:red" class="pa-0 ma-0">
                        Please enter in English.(영문으로 입력하세요)
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Weight <span style="color:#512da8">보석 중량</span></p>
                      </v-col>
                    </v-row>
                    <!-- 
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    -->
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                        <inputweight @setprice="setjwminweight" :value="search_jw_min_weight"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setjwmaxweight" :value="search_jw_max_weight"/>
                      </v-col>
                      <!-- 
                      <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <v-range-slider
                          v-model="search_range_jwweight"
                          :min="0.2"
                          :max="30"
                          :step="0.01"
                          color="#512da8"
                          track-color="gray"
                          thumb-color="black"
                          @input="setJwWeightRange"
                          hide-details dense
                          />
                      </v-col>
                      -->
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Type <span style="color:#512da8">금속종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_jewely_metaltype"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in METALLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 110 : 86" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  style="border: 2px solid black !important;border-radius:0px"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Content <span style="color:#512da8">함량</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_jewely_metalweight"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in WEIGHTLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 60 : 20" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  :x-small="$vuetify.breakpoint.smAndDown"
                                  style="border: 2px solid black !important;border-radius:0px"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>                    

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Weight <span style="color:#512da8">금속 중량</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">g</div>  
                        <inputweight @setprice="setjwmingram" :value="search_jw_min_gram"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setjwmaxgram" :value="search_jw_max_gram"/>
                      </v-col>
                    </v-row>

                  </template>

                  <!-- 세공 디자인 -->
                  <template v-if="PRODUCT_CLASS == 3">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Type <span style="color:#512da8">금속종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_ds_metaltype"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in METALLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 110 : 86" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  style="border: 2px solid black !important;border-radius:0px"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Content <span style="color:#512da8">함량</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_ds_metalweight"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in WEIGHTLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 60 : 20" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  :x-small="$vuetify.breakpoint.smAndDown"
                                  style="border: 2px solid black !important;border-radius:0px"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>                    

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Weight <span style="color:#512da8">금속 중량</span></p>
                      </v-col>
                    </v-row>
                    <!-- 
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    -->
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">g</div>  
                        <inputweight @setprice="setdsmingram" :value="search_ds_min_weight"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setdsmaxgram" :value="search_ds_max_weight"/>
                      </v-col>
                      <!-- 
                      <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <v-range-slider
                          v-model="search_range_jwgram"
                          :min="0"
                          :max="100"
                          :step="0.1"
                          color="#512da8"
                          track-color="gray"
                          thumb-color="black"
                          @input="setJwGramRange"
                          hide-details dense
                          />
                      </v-col>
                      -->
                    </v-row>

                  </template>         

                  <!-- 젬스톤 감정서 -->
                  <v-row v-if="PRODUCT_CLASS == 1 && vivid == 0" dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Certificate <span style="color:#512da8">감정서</span></p>
                    </v-col>
                  </v-row>
                  <v-row v-if="PRODUCT_CLASS == 1 && vivid == 0" dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row v-if="PRODUCT_CLASS == 1 && vivid == 0" dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_gem_certi"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in GEMCERTILIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 70 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                style="border: 2px solid black !important;border-radius:0px"
                                elevation="1" class="mr-1 mt-1" :key="item.IDX" :value="item.IDX">
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:11px'" 
                              >{{item.NAME}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>                  

                  
                  <!-- 상점번호 검색 -->
                  <v-row dense align="center" justify="start" class="mt-3 ma-0">
                    
                      <p class="text-left ma-0 pa-0 pl-md-10 pr-md-3 pr-1 pl-1" style="color:black"
                      :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px;line-height:14px' : 'font-size:12px;line-height:12px'" 
                      >공급업체 고유번호<br>Supplier Number</p>
                    
                    <v-col cols="6" md="5" align="center" class="pa-0 ma-0">
                      <input
                        dense single-line clearable
                        v-model="search_shopnumber" 
                        color="#000000"
                        hide-details 
                        class="basicsearchstyle_gem"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" class="ma-0 pa-0 d-flex">
                      <input type="checkbox" id="shipcheck" name="shipcheck" class="custom-checkbox" v-model="isquick" @change="ischeckchange"/>
                      <p for="shipcheck" style="color:black;font-size:14px;margin-left:5px">즉시납품 Domestic STOCK in Korea</p>
                    </v-col>
                  </v-row>


                </v-col>
              </v-row>
            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="clear_search_where">Clear 초기화</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="apply_search">Search 검색</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
      
    </v-row>

    <!-- 제품 리스트 -->
    <v-row align="center" justify="center">
    <v-col class="md-mt-5" cols="12" md="8">

    <v-row v-if="issale == 1 " justify="center" class="pa-0" dense>
      <v-carousel
        v-model="currentIndex"
        cycle
        height="40"
        hide-delimiter-background
        hide-delimiters
        :show-arrows="false"
        >
        <v-carousel-item
          v-for="(event, i) in eventList"
          :key="i" 
          @click="goEventDetail(event)" style="cursor:pointer"
          >
          <div class="text-center" style="height:30px;line-height:30px;font-weight:bold;overflow: hidden;white-space:nowrap;text-overflow:ellipsis">
            {{ event.TITLE }}
          </div>
        </v-carousel-item>
      </v-carousel>      
      <!-- 
      <div style="position:absolute;width:100%;height:50px;background:#ff000080;text-align:center;line-height:50px;overflow:hidden">
        <label id="text1" style="position:absolute;left:0px;z-index:1;visibility:visible;opacity:1;width:100%;height:50px;overflow:hidden;background:#ff00ff80;"></label>
        <label id="text2" style="position:absolute;left:0px;z-index:0;visibility:visible;opacity:0;width:100%;height:50px;overflow:hidden;background:#ffff0080;"></label>
      </div>
      -->
    </v-row>

    <v-divider v-if="issale == 1"/>

    <v-row v-if="issale == 1" justify="center" class="mt-1 mb-1">
      <v-col cols="10" md="5">
        <v-row align="center" justify="center">
          <v-col cols="4" align="center" class="pa-0">
            <v-btn 
              :color="PRODUCT_CLASS == 0 ? 'white' : 'gray'" 
              :style="PRODUCT_CLASS == 0 ? 'background:black' : ''"
              text class="text-md-body-1 text-body1 text-center" @click="type_select(0)">Diamond</v-btn>
          </v-col>
          <v-col cols="4" align="center" class="pa-0">
            <v-btn 
              :color="PRODUCT_CLASS == 1 ? 'white' : 'gray'" 
              :style="PRODUCT_CLASS == 1 ? 'background:black' : ''"
            text class="text-md-body-1 text-body1 text-center" @click="type_select(1)">Gemstone</v-btn>
          </v-col>
          <v-col cols="4" align="center" class="pa-0">
            <v-btn 
              :color="PRODUCT_CLASS == 2 ? 'white' : 'gray'" 
              :style="PRODUCT_CLASS == 2 ? 'background:black' : ''"
            text class="text-md-body-1 text-body1 text-center" @click="type_select(2)">Jewelry</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-divider v-if="issale == 1"/>

      <v-row dense align="center" justify="center" class="mt-md-3">

        <v-col md="6" cols="0" v-if="vivid == 0">
          <p class="text-md-h6 text-subtitle-2 hidden-sm-and-down">{{ PRODUCT_CLASS == 0 ? dtype == null || dtype == undefined ? 'Diamond 다이아몬드' :  dtype == 0 ? 'Natural Diamond 천연 다이아몬드' : 'Lab-Grown Diamond 렙-그로운 다이아몬드' : PRODUCT_CLASS == 1 ? 'Gemstone 젬스톤' : PRODUCT_CLASS == 2 ? 'Jewelry 주얼리' : 'Jewelry Making 주얼리 세공의뢰'}} </p>
        </v-col>

        <v-col md="6" cols="10" v-if="vivid == 1" :align="$vuetify.breakpoint.smAndDown ? 'center':'left'">
          <v-img
            :width="$vuetify.breakpoint.smAndDown ? 160 : 200"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom:5px' : ''"
            :src="require(`@/assets/vividlogo.png`)"
            @click="govivid"
            />
        </v-col>

        <v-col cols="10" md="6" @click="search_dialog = true" style="cursor:pointer">
          <!-- 
          <v-btn class="none_radius" width="100%" min-height="50" style="color:white" color="#000000" @click="search_dialog = true">
          </v-btn>
          -->

            <v-row justify="center" align="center">
              
              <v-col align-self="center" cols="2" 
                class="d-flex justify-center mr-1" 
                style="background:white;border:2px black solid;padding:0px;height:56px;max-width:56px"
                >
                <v-icon large color="black">mdi-magnify</v-icon>
              </v-col>

              <v-col cols="9" 
                class="none_radius d-flex flex-column justify-start" 
                style="background:black;color:white;height:50px;padding-left:10px;padding-top:4px"
                >
                <div style="text-align:start;">Search Option Setting</div>
                <div style="text-align:start;font-size:12px">내가 찾는 기준선택 검색(크기,가격,4C 등)</div>
              </v-col>
            </v-row>
          
        </v-col>

        <!-- 
        <v-col cols="10" md="3">
          <v-btn class="none_radius" width="100%" min-height="50" style="color:white" color="#000000" @click="check_dialog = true">
            <v-row justify="center">
              <v-col align-self="center" cols="2">
                <v-icon class="mr-2">mdi-information-slab-circle</v-icon>
              </v-col>
              <v-col cols="10">
                <div style="text-align:start">Check before use</div>
                <div style="text-align:start;font-size:18px">이용 전 확인 안내</div>
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        -->
        
        
      </v-row>

      <v-row >

        <!-- 모바일 해상도 -->
        <template v-if="itemList.length > 0">
        <v-col
          v-for="item in itemList"
          cols="6"
          class="hidden-md-and-up mt-0"
          >
          <v-card @click="loadnotice(item)" tile elevation="0">
            <v-hover v-slot="{ hover }" style="overflow:hidden;">
              <div>
              <v-img 
                width="100%" aspect-ratio="1"
                :src="item.PRODUCT_IMG1"
                :lazy-src="item.THUM_IMG1"
                :class="{'imgscaleup':hover}"
                >
                <div v-if="item.SALERATEVALUE < 1" class="text_sale">SALE {{ item.SALERATE }}%</div> 
                <!-- <div v-if="item.ISQUICK == 1" class="text_ship"><v-icon color="white">mdi-truck</v-icon></div> -->
              </v-img>
              </div>
            </v-hover>          
            <v-card-text class="pa-0 pl-3 pt-2 text_2line" style="color:black;font-size:14px;line-height:15px" v-text="item.PRODUCT_NUMBER"></v-card-text>
            <v-card-text class="pa-0 pl-3 pb-0" style="color:black;font-size:14px;line-height:15px">
              <template v-if="item.PRODUCT_CLASS == 0">
                <template v-if="item.DIAMOND_CLASS == 0">
                  <!-- 다이아몬드 -->
                  {{ item.WEIGHT+' ' + item.CERTI_NAME + getIndextoValue(item.COLOR, COLORLIST) + getIndextoValue(item.TRANSPARENCY, TRANSLIST) + getIndextoValue(item.EVALUATION, DISEVALLIST) + getIndextoValue(item.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                </template>
                <template v-else>
                  {{ item.WEIGHT+' ' + item.CERTI_NAME + getIndextoValue(item.DIAMONDCOLOR, DIAMONDCOLORLIST) + getIndextoValue(item.TRANSPARENCY, TRANSLIST) + getIndextoValue(item.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                </template>
                
              </template>
              <template v-else-if="item.PRODUCT_CLASS == 1">
                {{ 'Weight ' + item.WEIGHT + ' ' + item.CERTI_NAME }}
              </template>
              <template v-else-if="item.PRODUCT_CLASS == 2">
                {{ item.MAINSTONE +' '+ item.MAINWEIGHT }}
              </template>
            </v-card-text>
            <template v-if="item.PRODUCT_CLASS == 2">
              <v-card-text class="pa-0 pl-3 pb-0" style="color:black;font-size:14px;line-height:15px">
                {{ getIndextoValue(item.METALTYPE, METALLIST) + getIndextoValue(item.METALWEIGHT, WEIGHTLIST) +' '+ item.WEIGHT }}
              </v-card-text>
            </template>
            <v-card-text :style="grade > 1 ? 'color:red' : 'color:black'" class="pa-0 pl-3 pb-0" style="font-size:14px;line-height:15px" v-text="'₩ '+item.PRICE_DP_WON+' 원'"></v-card-text>
            <v-card-text :style="grade > 1 ? 'color:red' : 'color:black'" class="pa-0 pl-3 pb-1" style="font-size:14px;line-height:14px" v-text="'$ '+item.PRICE_DP_USD+' USD'"></v-card-text>
          </v-card>
        </v-col>
        </template>
        <template v-else>
          <template v-if="first_flag">
            <v-col cols="12" class="hidden-md-and-up text-center mt-15 pt-15">
              <div class="inter_font" style="font-weight:bold;font-size:16px">Sorry, there are no items in the condition you searched for. Please contact the head office</div><br>
              <div class="notosanskr_font" style="font-weight:bold;font-size:16px">죄송합니다. 검색하신 조건의 품목이 없습니다. 본사로 문의해주세요</div>
              <v-btn elevation="0" class="white_btn_freesize mt-15" width="200" height="50px" @click="clear_search">Search Clear<br>검색조건 초기화</v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="index in 20" :key="index"
              cols="6"
              class="hidden-md-and-up mt-5"
              >            
              <v-skeleton-loader
                type="card, list-item-two-line"
                ></v-skeleton-loader>         
            </v-col>   
          </template>
        </template>
        

        <!-- PC해상도 -->
        <template v-if="itemList.length > 0">
          <v-col
            v-for="item in itemList"
            cols="3"
            class="hidden-sm-and-down mt-1"
            >
              <v-card @click="loadnotice(item)" tile elevation="0">
                <v-hover v-slot="{ hover }" style="overflow:hidden;">
                  <div>
                    <v-img 
                      width="100%" aspect-ratio="1"
                      :src="item.PRODUCT_IMG1"
                      :lazy-src="item.THUM_IMG1"
                      :class="{'imgscaleup':hover}"
                      >
                      <div v-if="item.SALERATEVALUE < 1" class="text_sale">SALE {{ item.SALERATE }}%</div> 
                      <!-- <div v-if="item.ISQUICK == 1" class="text_ship"><v-icon color="white">mdi-truck</v-icon></div> -->
                    </v-img>
                    
                  </div>
                </v-hover>
                <v-card-text class="pa-0 pl-3 pt-3 text_2line" style="color:black;font-size:22px;line-height:23px" v-text="item.PRODUCT_NUMBER"></v-card-text>
                <v-card-text class="pa-0 pl-3 pb-0" style="color:black;font-size:18px;">
                  <template v-if="item.PRODUCT_CLASS == 0">
                    <template v-if="item.DIAMOND_CLASS == 0">
                      <!-- 다이아몬드 -->
                      {{ item.WEIGHT+' ' + item.CERTI_NAME + getIndextoValue(item.COLOR, COLORLIST) + getIndextoValue(item.TRANSPARENCY, TRANSLIST) + getIndextoValue(item.EVALUATION, DISEVALLIST) + getIndextoValue(item.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                    </template>
                    <template v-else>
                      {{ item.WEIGHT+' ' + item.CERTI_NAME + getIndextoValue(item.DIAMONDCOLOR, DIAMONDCOLORLIST) + getIndextoValue(item.TRANSPARENCY, TRANSLIST) + getIndextoValue(item.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                    </template>
                    
                  </template>
                  <template v-else-if="item.PRODUCT_CLASS == 1">
                    {{ 'Weight ' + item.WEIGHT + ' ' + item.CERTI_NAME }}
                  </template>
                  <template v-else-if="item.PRODUCT_CLASS == 2">
                    {{ item.MAINSTONE +' '+ item.MAINWEIGHT }}
                  </template>
                </v-card-text>
                <template v-if="item.PRODUCT_CLASS == 2">
                  <v-card-text class="pa-0 pl-3 pb-0" style="color:black;font-size:18px;">
                    {{ getIndextoValue(item.METALTYPE, METALLIST) + getIndextoValue(item.METALWEIGHT, WEIGHTLIST) +' '+ item.WEIGHT }}
                  </v-card-text>
                </template>
                <v-card-text :style="grade > 1 ? 'color:red' : 'color:black'" class="pa-0 pl-3 pb-0" style="font-size:18px;" v-text="'₩ '+item.PRICE_DP_WON+' 원'"></v-card-text>
                <v-card-text :style="grade > 1 ? 'color:red' : 'color:black'" class="pa-0 pl-3 pb-1" style="font-size:18px;line-height:18px" v-text="'$ '+item.PRICE_DP_USD+' USD'"></v-card-text>
              </v-card>
          </v-col>
        </template>
        <template v-else>
          <template v-if="first_flag">
            <v-col cols="12" class="hidden-sm-and-down text-center mt-15 pt-15">
              <div class="inter_font" style="font-weight:bold;font-size:16px">Sorry, there are no items in the condition you searched for. Please contact the head office</div><br>
              <div class="notosanskr_font" style="font-weight:bold;font-size:16px">죄송합니다. 검색하신 조건의 품목이 없습니다. 본사로 문의해주세요</div>
              <v-btn elevation="0" class="white_btn_freesize mt-15" width="200" height="50px" @click="clear_search">Search Clear<br>검색조건 초기화</v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="index in 20" :key="'pc'+index"
              cols="3"
              class="hidden-sm-and-down mt-10"
              >            
              <v-skeleton-loader
                type="card, list-item-two-line"
                ></v-skeleton-loader>         
            </v-col>   
          </template>
        </template>

        <!-- 구매안내 
        <buyinfo />
        -->
        <!-- 
        <div>
          <v-btn color="success" @click="gobuyinfo"
                :class="$vuetify.breakpoint.smAndDown ? 'fixed_rightbottom_mb text-caption' : 'fixed_rightbottom text-body-2 pt-6 pb-6'"
                >
                How to buy<br>구매안내
          </v-btn>
        </div>
        -->

      </v-row>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>


    </v-col>
    </v-row> 

    <!-- 
    <div class="circle-button"
      style="position:fixed;right:20px;bottom:60px;"   
      @click="openchat">
    </div>
    -->

  </v-container>
</template>

<script>

export default {

    beforeDestroy(){
      if(this.timer != null){
        clearInterval(this.timer);
      }
    },

    mounted() {
      //화면 높이 알아내기
      this.grade = this.$session.get("GRADE");

      this.PRODUCT_CLASS = this.$route.query.code;
      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }

      //세일타입 (할인이벤트)
      this.issale = this.$route.query.stype;
      if(this.issale == undefined || this.issale == null){
        this.issale = 0;
      }
      var tempsale = this.$session.get("PRODUCT_SALE");
      this.$session.set("PRODUCT_SALE", this.issale);

      this.isquick = this.$route.query.isquick;
      console.log("isquick : " + this.isquick);
      if(this.isquick == undefined || this.isquick == null){
        this.isquick = 0;
      }else{
        this.isquick = Number(this.$route.query.isquick);
      }

      let tempIndex = this.$route.query.curidx;
      if(tempIndex == undefined || tempIndex == null){
        this.currentIndex = 0;
      }
      this.currentIndex = Number(tempIndex);



      //텍스트 애니
      this.outFrame = [
        { transform: "translate(0px, 0px) rotate(0deg) scale(1)", opacity:1 },
        { transform: "translate(-100%, 0px) rotate(0deg) scale(1)", opacity:0 },
      ];
      this.aniOption = {
        delay: 0,           //시작전 딜레이
        endDelay: 0,        //종료후 딜레이
        duration: 1000,     //재생시간
        iterations: 1,      //반복횟수
        fill: "forwards",   //애니가 멈춘 위치에서 정지
        easing: "linear"
      }
      this.inFrame = [
        { transform: "translate(100%, 0px) rotate(0deg) scale(1)", opacity:0 },
        { transform: "translate(0%, 0px) rotate(0deg) scale(1)", opacity:1 },
      ];


      //검색조건 복원 이후에 다이아몬드 타입을 덮어쓴다.
      this.dtype = this.$route.query.dtype;
      //console.log("dtype : "+this.dtype);
      if(this.dtype == undefined || this.dtype == null){
        this.search_diamondtype = [];
        this.showDiamondType = true;
      }else{
        //천연, 합성을 타고온 경우 검색에서 다이아몬드 타입을 보여주지 않는다.
        this.search_diamondtype = [this.dtype];
        this.showDiamondType = false;
      }

      this.vivid = this.$route.query.vivid;
      if(this.vivid == undefined || this.vivid == null){
        this.vivid = 0;
      }else{
        //비비드 제품  
        //감정서 고정 - 2:vivid
        this.search_certi = [2];
      }
      //console.log("vivid : " + this.vivid);

      var tempclsss = this.$session.get("PRODUCT_CLASS");
      this.$session.set("PRODUCT_CLASS", this.PRODUCT_CLASS);
      if(this.PRODUCT_CLASS == tempclsss && this.issale == tempsale){
        //검색조건 복원
        this.loadSearchOption();
      }else{
        //검색조건 초기화
        this.clear_search_where();
      }

      //이벤트에서 진입시 최초에 검색조건 초기화하기 위함.
      if(this.PRODUCT_CLASS == -1){
        this.PRODUCT_CLASS = 0;
        this.$session.set("PRODUCT_CLASS", this.PRODUCT_CLASS);
      }
 
      //타입 가져오기 및 검색조건 초기화
      let typeload = this.$route.query.rtype;
      if(typeload == undefined || typeload == null){
        typeload = 1;
      }
      
      if(typeload == 1){
        //타입가져오기
        this.getTypeList();
      }else{
        this.DIASHAPELIST = this.$session.get("DIASHAPELIST");
        this.DIACERTILIST = this.$session.get("DIACERTILIST");
        this.JEWELRYKINDLIST = this.$session.get("JEWELRYKINDLIST");
        this.GEMCERTILIST = this.$session.get("GEMCERTILIST");
        this.PRODUCTTYPELIST = this.$session.get("PRODUCTTYPELIST");
        this.PRODUCTITEMLISTALL = this.$session.get("PRODUCTITEMLISTALL");
        this.PRODUCTITEMLIST = this.$session.get("PRODUCTITEMLIST");
        this.JEWCERTILIST = this.$session.get("JEWCERTILIST");
      }

      /*
      this.notice_dialog = localStorage.getItem("SHOWNOTICE");
      if(this.notice_dialog == null){
        this.notice_dialog = true;
      }
      */
      
      //사용법 팝업 노출 - 사용 안함 : 2023년 6월 13일 요청사항 처리
      /*
      if(localStorage.getItem("SHOWNOTICE") == null){
        this.notice_dialog = true;
      }else{
        if(localStorage.getItem("SHOWNOTICE") == "true"){
          //console.log("shownotice true");
          this.notice_dialog = true;
        }else{
          //console.log("shownotice false");
          this.notice_dialog = false;
        }
      }
      */

      //console.log("this.notice_dialog : " + this.notice_dialog);

      
      //로그인이 없어도 조회 가능
      this.initpage();

    },

    beforeRouteLeave(to, from, next){
      if(this.ks_dialog){
        this.ks_dialog = false;
        next(false);
      }else if(this.vs_dialog){
        this.vs_dialog = false;
        next(false);
      }else{
        next();
      }
    },

    data: () => ({
      tab:0,

      timer:null,
      inFrame:null,
      outFrame:null,
      aniOption:null,
      currentIndex:0,
      cycle:false,
      textFlag:true,

      notice_dialog: false,
      check_dialog: false,
      agree1:false,

      first_flag:false,
      buyinfo_dialog: false,
      ks_dialog: false,
      vs_dialog: false,

      PRODUCT_CLASS: 0,
      search_dialog: false,

      DIASHAPELIST: [],     //다이아몬드 모양
      DIACERTILIST: [],     //다이아몬드 인증서
      
      JEWELRYKINDLIST: [],  //보석종류
      GEMCERTILIST: [],     //보석 인증서
      
      PRODUCTTYPELIST: [],    //주얼리 유형
      PRODUCTITEMLISTALL: [], //주얼리 품목 전체
      PRODUCTITEMLIST: [],    //주얼리 유형에 따른 품목
      SELECTTITEMLIST: [],    //선택된 주얼리 유형값 백업 (하나만 쓰면 타입 바꿀시 초기화 되버림)
      SELECTTITEMMAP: {},   //선택이 해제된 토글 버튼값을 추적하기 위한 2차원 배열
      

      JEWCERTILIST: [],       //주얼리 인증서

      //다이아
      search_shape: [],
      search_color: [0,10],
      search_transparency: [0,11],
      search_grind_total: [0, 6],
      search_grind_polish: [0, 6],
      search_grind_syme: [0, 6],
      search_fluorescence:[], //형광성
      search_diamondcolor:[], //다이아몬드 컬러
      search_diamondtype:[],  //다이아몬드 타입 - 천연/합성
      showDiamondType:true,
      dtype:null,
      vivid:0,

      //젬스톤
      search_gemstone_type: [],
      search_gem_min_weight: null,
      search_gem_max_weight: null,
      //search_range_gemweight: [0, 30],
      search_gem_certi: [],           //감정서
      
      //다이아몬드 잼스톤 
      search_min_weight: null,
      search_max_weight: null,
      //search_range_weight: [0, 20],
      search_certi: [],           //감정서

      //주얼리
      search_jw_min_weight: null,
      search_jw_max_weight: null,
      //search_range_jwweight: [0, 30],
      search_jw_min_gram: null,
      search_jw_max_gram: null,
      //search_range_jwgram: [0, 100],
      search_jw_name: '',
      
      search_jewely_metaltype:[],   //메탈타입(금속종류)
      search_jewely_metalweight:[], //함량
      search_jewely_type: [],
      search_jewely_item: [],

      //디자인 세공
      search_ds_min_weight: null,
      search_ds_max_weight: null,
      search_ds_metaltype:[],   //메탈타입(금속종류)
      search_ds_metalweight:[], //함량

      //공통
      search_min_price:null,      //달러
      search_max_price:null,
      search_min_price2:null,     //원화
      search_max_price2:null,
      //search_range_price:  [0, 99999999],
      //search_range_price2: [0, 999999999],

      search_shopnumber: null,

      weight_max_slider:100,

      //검색용 평가
      EVALLIST: [
          {name:'No Grade',    value:9}
        , {name:'Ideal',       value:5}
        , {name:'Excellent',   value:0}
        , {name:'Very Good',   value:1}
        , {name:'Good',        value:2}
        , {name:'Fair',        value:3}
        , {name:'Poor',        value:4}
      ],
      EVALNAMES1:['EX', 'VG', 'GD', 'FR', 'PR', 'ID', 'NG'],
      EVALNAMES2:['Excellent', 'Very Good', 'Good', 'Fair', 'Poor', 'Ideal', 'No Grade'],

      //제목용 평가
      DISEVALLIST: [
          {name:'ID',   value:5}
        , {name:'EX',   value:0}
        , {name:'VG',   value:1}
        , {name:'G',    value:2}
        , {name:'F',    value:3}
        , {name:'P',    value:4}
      ],

      //제목용 컬러등급
      DISCOLORGRADELIST: [
          {name:'Faint',        value:3}
        , {name:'Very Light',   value:4}
        , {name:'Light',        value:5}
        , {name:'Fancy Light',  value:2}
        , {name:'Fancy',        value:6}
        , {name:'Fancy Intense',value:1}
        , {name:'Fancy Vivid',  value:0}
        , {name:'Fancy Dark',   value:7}
        , {name:'Fancy Deep',   value:8}
        , {name:'Other',        value:99}
      ],

      //투명도
      TRANSLIST: [
          {name:'FL',    value:0}
        , {name:'IF',    value:1}
        , {name:'VVS1',  value:2}
        , {name:'VVS2',  value:3}
        , {name:'VS1',   value:4}
        , {name:'VS2',   value:5}
        , {name:'SI1',   value:6}
        , {name:'SI2',   value:7}
        , {name:'SI3',   value:8}
        , {name:'I1',    value:9}
        , {name:'I2',    value:10}
        , {name:'I3',    value:11}
      ],
      TRANSNAMES:['FL','IF','VVS1','VVS2','VS1','VS2','SI1','SI2','SI3','I1','I2','I3'],
      
      //형광성 검색용
      FLUORESCENCELIST: [
          {name:'None',           value:0}
        , {name:'Faint',          value:1}
        , {name:'Faint Slight',   value:5}
        , {name:'Very Slight',    value:4}
        , {name:'Slight',         value:7}
        , {name:'Medium',         value:2}
        , {name:'Strong',         value:3}
        , {name:'Very Strong',    value:6}
        , {name:'N/A',            value:99}
      ],

      //형광성 제목용
      DIDFLUORESCENCELIST: [
          {name:'NON',  value:0}
        , {name:'FNT',  value:1}
        , {name:'FSL',  value:5}
        , {name:'VSL',  value:4}
        , {name:'SLT',  value:7}
        , {name:'MED',  value:2}
        , {name:'STG',  value:3}
        , {name:'VST',  value:6}
        , {name:'N/A',  value:99}
      ],
      FLUNAMES1:['NON', 'FNT', 'FSL', 'VSL', 'SLT', 'MED', 'STG', 'VST', 'N/A'],
      FLUNAMES2:['None', 'Faint', 'Faint Slight', 'Very Slight', 'Slight', 'Medium', 'Strong', 'Very Strong', 'N/A'],

      DIAMONDTYPELIST: [
          {name:'천연 다이아몬드\nNatural Diamond',    value:0}
        , {name:'렙-그로운 다이아몬드\nLab Grown Diamond',  value:1}
      ],

      COLORLIST: [
          {name:'D',  value:0}
        , {name:'E',  value:1}
        , {name:'F',  value:2}
        , {name:'G',  value:3}
        , {name:'H',  value:4}
        , {name:'I',  value:5}
        , {name:'J',  value:6}
        , {name:'K',   value:7}
        , {name:'L',   value:8}
        , {name:'M',   value:9}
        , {name:'N',   value:10}
      ],

      COLORSNAME: ['D','E','F','G','H','I','J','K','L','M','N'],

      DIAMONDCOLORLIST: [
          {name:'Red',    value:0, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/1red.png'}
        , {name:'Blue',   value:1, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/2blue.png'}
        , {name:'Pink',   value:2, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/3pink.png'}
        , {name:'Yellow', value:3, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/4yellow.png'}
        , {name:'Green',  value:4, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/5green.png'}
        , {name:'Brown',  value:5, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/6brown.png'}
        , {name:'Black',  value:6, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/7black.png'}
        , {name:'Other',  value:7, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/1666416842760.png'}
      ],

      //금속종류
      METALLIST:[
          {name:'Yellow Gold',value:0}
        , {name:'White Gold', value:1}
        , {name:'Pink Gold',  value:2}
        , {name:'Pure Gold',  value:3}
        , {name:'Platinum',   value:4}
        , {name:'Silver',     value:5}
        , {name:'Titanium',   value:6}
        , {name:'Other',      value:7}
      ],

      //금속함량
      WEIGHTLIST: [
          {name:'24k',   value:0}
        , {name:'22k',   value:1}
        , {name:'20k',   value:2}
        , {name:'18k',   value:3}
        , {name:'14k',   value:4}
        , {name:'12k',   value:5}
        , {name:'10k',   value:6}
        , {name:'999',   value:7}
        , {name:'995',   value:8}
        , {name:'950',   value:9}
        , {name:'900',   value:10}
      ],


      editedItem: {
      },

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      issale : 0,     //할인 이벤트 전용
      isquick: 0,     //즉시납품만 보기
      isstock: 0,     //공급업체 제품 보기

      //테이블 정의
      headers: [
        {text: '번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '상태', value: 'PRODUCT_STATE', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '가격', value: 'PRICE', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '구매자', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
      ],
      itemList: [],
      eventList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

      grade:0,

    }),

    created(){

    },


    methods: {

      openchat(){
        window.ChannelIO('showMessenger');
      },

      ischeckprovchange(){
        if(this.isstock){
          this.isstock = 1;
          //this.search_shopnumber = "0210-"+this.$session.get("ACCNT_IDX");
          this.search_shopnumber = this.$session.get("ACCNT_IDX");
          this.apply_search();
          //this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex, acntidx:}});
        }else{
          this.isstock = 0;
          this.search_shopnumber = null;
          this.apply_search();
          //this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex, acntidx:0}});
        }
      },

      /*
      async provstocklink(){
        if(navigator.clipboard){
          navigator.clipboard.writeText("아니! 이건! 클립보드야!")
          .then(()=>{
            this.$alert("링크가 클립보드에 복사되었습니다.");
          })
          .catch((error)=>{
            this.$alert("클립보드 에러!" + error);
          });

        }
      },
      */

      ischeckchange(){
        console.log("ischeckchange : " + this.isquick);
        if(this.isquick){
          this.isquick = 1;
        }else{
          this.isquick = 0;
        }
        //this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex }});
      },
      
      clickagree1(){
        this.agree1 = !this.agree1;
      },

      closenotice(){
        //console.log("this.agree1 : " + this.agree1 + " / " + moment().format("YYYY-MM-DD"));
        if(this.agree1){
          //팝업 계속 본다.
          localStorage.setItem("SHOWNOTICE", false);
        }else{
          //팝업 다시 안본다.
          localStorage.setItem("SHOWNOTICE", true);
          //localStorage.setItem("SHOWNOTICEDATE", moment().format("YYYY-MM-DD"));
        }
        this.notice_dialog = false;
      },


      closecheck(){
        this.check_dialog = false;
      },      


      getIndextoValue(value, list){
        if(value == null || value == undefined){
          return '';
        }
        for(var i=0;i<list.length;i++){
          var element = list[i];
          if(element.value == Number(value)){
            return ' '+element.name;
          }
        }
        return '';
      },

      gobuyinfo(){
        this.$router.push("/BuyInfo");
      },

      govivid(){
        this.$router.push("/VividDiamond");
      },

      setminprice(value){
        this.search_min_price   = value;
      },
      setmaxprice(value){
        this.search_max_price = value;
      },

      setminprice2(value){
        this.search_min_price2   = value;
      },
      setmaxprice2(value){
        this.search_max_price2 = value;
      },


      setminweight(value){
        this.search_min_weight = value;
        //this.search_range_weight = [value, this.search_range_weight[1]];
      },
      setmaxweight(value){
        this.search_max_weight = value;
        //this.search_range_weight = [this.search_range_weight[0], value];
      },
      //사용안함
      setWeightRange(){
        //this.search_min_weight = this.search_range_weight[0];
        //this.search_max_weight = this.search_range_weight[1];
      },

      setgemminweight(value){
        this.search_gem_min_weight = value;
        //this.search_range_gemweight = [value, this.search_range_gemweight[1]];
      },
      setgemmaxweight(value){
        this.search_gem_max_weight = value;
        //this.search_range_gemweight = [this.search_range_gemweight[0], value];
      },
      //사용안함
      setGemWeightRange(){
        //this.search_gem_min_weight = this.search_range_gemweight[0];
        //this.search_gem_max_weight = this.search_range_gemweight[1];
      },

      setjwminweight(value){
        this.search_jw_min_weight = value;
        //this.search_range_jwweight = [value, this.search_range_jwweight[1]];
      },
      setjwmaxweight(value){
        this.search_jw_max_weight = value;
        //this.search_range_jwweight = [this.search_range_jwweight[0], value];
      },
      //사용안함
      setJwWeightRange(){
        //this.search_jw_min_weight = this.search_range_jwweight[0];
        //this.search_jw_max_weight = this.search_range_jwweight[1];
      },

      setjwmingram(value){
        this.search_jw_min_gram = value;
        //this.search_range_jwgram = [value, this.search_range_jwgram[1]];
      },
      setjwmaxgram(value){
        this.search_jw_max_gram = value;
        //this.search_range_jwgram = [this.search_range_jwgram[0], value];
      },
      //사용안함
      setJwGramRange(){
        //this.search_jw_min_gram = this.search_range_jwgram[0];
        //this.search_jw_max_gram = this.search_range_jwgram[1];
      },
      setdsmingram(value){
        this.search_ds_min_weight = value;
        //this.search_range_jwgram = [value, this.search_range_jwgram[1]];
      },
      setdsmaxgram(value){
        this.search_ds_max_weight = value;
        //this.search_range_jwgram = [this.search_range_jwgram[0], value];
      },



      loadSearchOption(){

        //다이아
        this.search_shape = this.$session.get("search_shape");
        if(this.search_shape == undefined){
            this.search_shape = [];
        }
        this.search_color = this.$session.get("search_color");
        if(this.search_color == undefined){
            this.search_color = [0, 10];
        }
        this.search_transparency = this.$session.get("search_transparency");
        if(this.search_transparency == undefined){
            this.search_transparency = [0, 11];
        }
        this.search_grind_total = this.$session.get("search_grind_total");
        if(this.search_grind_total == undefined){
            this.search_grind_total = [0,6];
        }
        this.search_grind_polish = this.$session.get("search_grind_polish");
        if(this.search_grind_polish == undefined){
            this.search_grind_polish = [0,6];
        }
        this.search_grind_syme = this.$session.get("search_grind_syme");
        if(this.search_grind_syme == undefined){
            this.search_grind_syme = [0,6];
        }
        this.search_diamondcolor = this.$session.get("search_diamondcolor");
        if(this.search_diamondcolor == undefined){
            this.search_diamondcolor = [];
        }
        this.search_fluorescence = this.$session.get("search_fluorescence");
        if(this.search_fluorescence == undefined){
            this.search_fluorescence = [];
        }

        if(this.showDiamondType){
          //다이아몬트 타입을 사용하는 경우에만 로드 (천연, 합성은 사용 금지)
          this.search_diamondtype = this.$session.get("search_diamondtype");
          if(this.search_diamondtype == undefined){
            this.search_diamondtype = [];
          }          
        }
        
        //젬스톤
        this.search_gemstone_type = this.$session.get("search_gemstone_type");
        if(this.search_gemstone_type == undefined){
            this.search_gemstone_type = [];
        }
        this.search_gem_min_weight = this.$session.get("search_gem_min_weight");
        if(this.search_gem_min_weight == undefined){
            this.search_gem_min_weight = 0.1;
        }
        this.search_gem_max_weight = this.$session.get("search_gem_max_weight");
        if(this.search_gem_max_weight == undefined){
            this.search_gem_max_weight = 100;
        }
        //this.search_range_gemweight = [this.search_gem_min_weight, this.search_gem_max_weight];

        this.search_gem_certi = this.$session.get("search_gem_certi");
        if(this.search_gem_certi == undefined){
            this.search_gem_certi = [];
        }

         //다이아몬드 잼스톤 - 비비드가 아닌 경우에만 인증서를 로드해라.
        if(this.vivid == 0){
          //비비드면 인증기관 건들면 안됨.
          this.search_certi = this.$session.get("search_certi");
          if(this.search_certi == undefined){
              this.search_certi = [];
          }
          
        }
       
        this.search_min_weight = this.$session.get("search_min_weight");
        if(this.search_min_weight == undefined){
            this.search_min_weight = 0.1;
        }
        this.search_max_weight = this.$session.get("search_max_weight");
        if(this.search_max_weight == undefined){
            this.search_max_weight = 100;
        }
        //this.search_range_weight = [this.search_min_weight, this.search_max_weight];

        //주얼리
        this.search_jw_min_weight = this.$session.get("search_jw_min_weight");
        if(this.search_jw_min_weight == undefined){
            this.search_jw_min_weight = 0;
        }
        this.search_jw_max_weight = this.$session.get("search_jw_max_weight");
        if(this.search_jw_max_weight == undefined){
            this.search_jw_max_weight = 100;
        }
        //this.search_range_jwweight = [this.search_jw_min_weight, this.search_jw_max_weight];

        this.search_jw_min_gram = this.$session.get("search_jw_min_gram");
        if(this.search_jw_min_gram == undefined){
            this.search_jw_min_gram = 0;
        }
        this.search_jw_max_gram = this.$session.get("search_jw_max_gram");
        if(this.search_jw_max_gram == undefined){
            this.search_jw_max_gram = 100;
        }
        //this.search_range_jwgram = [this.search_jw_min_gram, this.search_jw_max_gram];

        this.search_jw_name = this.$session.get("search_jw_name");
        if(this.search_jw_name == undefined){
            this.search_jw_name = null;
        }

        this.search_jewely_metalweight = this.$session.get("search_jewely_metalweight");
        if(this.search_jewely_metalweight == undefined){
            this.search_jewely_metalweight = [];
        }
        this.search_jewely_metaltype = this.$session.get("search_jewely_metaltype");
        if(this.search_jewely_metaltype == undefined){
            this.search_jewely_metaltype = [];
        }
        this.search_jewely_type = this.$session.get("search_jewely_type");
        if(this.search_jewely_type == undefined){
            this.search_jewely_type = [];
        }else{
            this.search_jewely_type = [1];
        }

        this.search_jewely_item = this.$session.get("search_jewely_item");
        if(this.search_jewely_item == undefined){
            this.search_jewely_item = [];
        }
        this.SELECTTITEMMAP = this.$session.get("SELECTTITEMMAP");
        if(this.SELECTTITEMMAP == undefined){
            this.SELECTTITEMMAP = {};
        }
        
        //디자인 세공
        this.search_ds_min_weight = this.$session.get("search_ds_min_weight");
        if(this.search_ds_min_weight == undefined){
            this.search_ds_min_weight = 0;
        }
        this.search_ds_max_weight = this.$session.get("search_ds_max_weight");
        if(this.search_ds_max_weight == undefined){
            this.search_ds_max_weight = 100;
        }        
        this.search_ds_metalweight = this.$session.get("search_ds_metalweight");
        if(this.search_ds_metalweight == undefined){
            this.search_ds_metalweight = [];
        }
        this.search_ds_metaltype = this.$session.get("search_ds_metaltype");
        if(this.search_ds_metaltype == undefined){
            this.search_ds_metaltype = [];
        }

        //공통
        this.search_min_price = this.$session.get("search_min_price");
        if(this.search_min_price == undefined){
            this.search_min_price = null;
        }
        this.search_max_price = this.$session.get("search_max_price");
        if(this.search_max_price == undefined){
            this.search_max_price = null;
        }

        this.search_min_price2 = this.$session.get("search_min_price2");
        if(this.search_min_price2 == undefined){
            this.search_min_price2 = null;
        }
        this.search_max_price2 = this.$session.get("search_max_price2");
        if(this.search_max_price2 == undefined){
            this.search_max_price2 = null;
        }

        this.search_shopnumber = this.$session.get("search_shopnumber");
        if(this.search_shopnumber == undefined || this.search_shopnumber == null || String(this.search_shopnumber).length < 1){
            this.search_shopnumber = null;
            this.isstock = 0;
        }else{
            this.isstock = 1;
        }

      },     
      

      //검색조건 저장
      saveSearchOption(){
        //다이아
        this.$session.set("search_shape", this.search_shape);
        this.$session.set("search_color", this.search_color);
        this.$session.set("search_transparency", this.search_transparency);
        this.$session.set("search_grind_total", this.search_grind_total);
        this.$session.set("search_grind_polish", this.search_grind_polish);
        this.$session.set("search_grind_syme", this.search_grind_syme);

        this.$session.set("search_diamondcolor", this.search_diamondcolor);
        this.$session.set("search_fluorescence", this.search_fluorescence);

        if(this.showDiamondType){
          //다이아몬트 타입을 사용하는 경우에만 저장 (천연, 합성은 사용 금지)
          this.$session.set("search_diamondtype", this.search_diamondtype);
        }
        

        //젬스톤
        this.$session.set("search_gemstone_type", this.search_gemstone_type);
        this.$session.set("search_gem_min_weight", this.search_gem_min_weight);
        this.$session.set("search_gem_max_weight", this.search_gem_max_weight);
        this.$session.set("search_gem_certi", this.search_gem_certi);

        //다이아몬드 잼스톤
        if(this.vivid == 0){
          //비비드면 인증기관 건들면 안됨.
          this.$session.set("search_certi", this.search_certi);
        }
        
        this.$session.set("search_min_weight", this.search_min_weight);
        this.$session.set("search_max_weight", this.search_max_weight);

        //주얼리
        this.$session.set("search_jw_min_weight", this.search_jw_min_weight);
        this.$session.set("search_jw_max_weight", this.search_jw_max_weight);
        this.$session.set("search_jw_min_gram", this.search_jw_min_gram);
        this.$session.set("search_jw_max_gram", this.search_jw_max_gram);
        this.$session.set("search_jw_name", this.search_jw_name);

        this.$session.set("search_jewely_metalweight", this.search_jewely_metalweight);
        this.$session.set("search_jewely_metaltype", this.search_jewely_metaltype);
        this.$session.set("search_jewely_type", this.search_jewely_type);

        this.getSelectJewelyItem();
        this.search_jewely_item = [...this.SELECTTITEMLIST];
        this.$session.set("search_jewely_item", this.search_jewely_item);
        this.$session.set("SELECTTITEMMAP", this.SELECTTITEMMAP);

        //디자인 세공
        this.$session.set("search_ds_min_weight", this.search_ds_min_weight);
        this.$session.set("search_ds_max_weight", this.search_ds_max_weight);
        this.$session.set("search_ds_metalweight", this.search_ds_metalweight);
        this.$session.set("search_ds_metaltype", this.search_ds_metaltype);

        //공통
        this.$session.set("search_min_price", this.search_min_price);
        this.$session.set("search_max_price", this.search_max_price);
        this.$session.set("search_min_price2", this.search_min_price2);
        this.$session.set("search_max_price2", this.search_max_price2);

        this.$session.set("search_shopnumber", this.search_shopnumber);
        if(this.search_shopnumber == undefined || this.search_shopnumber == null || String(this.search_shopnumber).length < 1){
          this.isstock = 0;
        }else{
          this.isstock = 1;
        }
        

      },
      


      //타입 가져오기
      getTypeList(){
        
        this.DIASHAPELIST.splice(0, this.DIASHAPELIST.length); 
        this.DIACERTILIST.splice(0, this.DIACERTILIST.length); 
        this.JEWELRYKINDLIST.splice(0, this.JEWELRYKINDLIST.length); 
        this.GEMCERTILIST.splice(0, this.GEMCERTILIST.length); 
        this.PRODUCTTYPELIST.splice(0, this.PRODUCTTYPELIST.length); 
        this.PRODUCTITEMLISTALL.splice(0, this.PRODUCTITEMLISTALL.length); 
        this.PRODUCTITEMLIST.splice(0, this.PRODUCTITEMLIST.length); 
        this.JEWCERTILIST.splice(0, this.JEWCERTILIST.length); 

        this.$session.remove("DIASHAPELIST");
        this.$session.remove("DIACERTILIST");
        this.$session.remove("JEWELRYKINDLIST");
        this.$session.remove("GEMCERTILIST");
        this.$session.remove("PRODUCTTYPELIST");
        this.$session.remove("PRODUCTITEMLISTALL");
        this.$session.remove("PRODUCTITEMLIST");
        this.$session.remove("JEWCERTILIST");

        this.$http.post(this.$host+'/GuestTotalTypeList',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list1 = result.data.DiamondShape;
              list1.forEach(element => {
                  this.DIASHAPELIST.push(element); 
              });
              const list2 = result.data.DiamondCerti;
              list2.forEach(element => {
                  this.DIACERTILIST.push(element); 
              });
              const list3 = result.data.GemstoneType;
              list3.forEach(element => {
                  this.JEWELRYKINDLIST.push(element); 
              });
              const list4 = result.data.GemstoneCerti;
              list4.forEach(element => {
                  this.GEMCERTILIST.push(element); 
              });
              const list5 = result.data.JewelryType;
              list5.forEach(element => {
                  this.PRODUCTTYPELIST.push(element); 
                  this.SELECTTITEMMAP[element.IDX] = [];
              });

              const list6 = result.data.JewelryItem;
              list6.forEach(element => {
                  this.PRODUCTITEMLISTALL.push(element); 
              });
              this.setJewelyItem(this.PRODUCTTYPELIST[0].IDX);

              const list7 = result.data.JewelryCerti;
              list7.forEach(element => {
                  this.JEWCERTILIST.push(element); 
              });

              //스토리지 저장
              this.$session.set("DIASHAPELIST", this.DIASHAPELIST);
              this.$session.set("DIACERTILIST", this.DIACERTILIST);
              this.$session.set("JEWELRYKINDLIST", this.JEWELRYKINDLIST);
              this.$session.set("GEMCERTILIST", this.GEMCERTILIST);
              this.$session.set("PRODUCTTYPELIST", this.PRODUCTTYPELIST);
              this.$session.set("PRODUCTITEMLISTALL", this.PRODUCTITEMLISTALL);
              this.$session.set("PRODUCTITEMLIST", this.PRODUCTITEMLIST);
              this.$session.set("JEWCERTILIST", this.JEWCERTILIST);

          }else if(result.data.resultCode == 2){
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },      

      changejewelrytype(){
        
        this.setJewelyItem(this.search_jewely_type);

        //선택값 복원
        this.getSelectJewelyItem();
        this.search_jewely_item = [...this.SELECTTITEMLIST];
        
      },

      //주얼리 아이템 선택을 변경하거나 위의 함수에서 this.search_jewely_item을 변경하면 실행됨.
      changejewelryitem(value){

        //value 중복값 제거
        value = [...new Set(value)];

        //선택 가능한 값만 처리
        const selectvalue = [];
        value.forEach(str=>{
          for(let i=0;i<this.PRODUCTITEMLIST.length;i++){
            if(this.PRODUCTITEMLIST[i].IDX == str){
              selectvalue.push(str);
              break;
            }
          }
        })

        const set = new Set([...selectvalue]);
        this.SELECTTITEMMAP[this.search_jewely_type] = [...set];
        
        //선택된 주얼리 아이템 복원
        this.getSelectJewelyItem();
      },

      getSelectJewelyItem(){
        //선택된 주얼리 아이템값 복원
        this.SELECTTITEMLIST.splice(0, this.SELECTTITEMLIST.length);
        //백업된 선택 리스트로 선택 리스트 재생성.
        for (const key in this.SELECTTITEMMAP) {
          if (this.SELECTTITEMMAP.hasOwnProperty(key)) {
            // 각 키(key)에 대한 처리
            const value = this.SELECTTITEMMAP[key];
            //console.log(`Key: ${key}, Value: ${value}`);
            this.SELECTTITEMLIST = this.SELECTTITEMLIST.concat(value);
          }
        }
        const set = new Set(this.SELECTTITEMLIST);
        this.SELECTTITEMLIST = [...set];
        
      },

      //주얼리 유형에 따른 아이템 리스트 가져오기
      setJewelyItem(idx){
        if(idx == null || idx == undefined || idx.length < 1) return;
        this.PRODUCTITEMLIST.splice(0, this.PRODUCTITEMLIST.length);
        for(var i=0;i<this.PRODUCTITEMLISTALL.length;i++){
          var item = this.PRODUCTITEMLISTALL[i];
          if(item.PRODUCTTYPE_IDX == idx){
            this.PRODUCTITEMLIST.push(item);
          }
        }
      },

      go_home(){
        
        if(localStorage.getItem("SHOWNOTICE") == null){
        }else{
          if(localStorage.getItem("SHOWNOTICE") == "true"){
          }else{
            //console.log("shownotice false");
            this.agree1 = true;
          }
        }
        this.notice_dialog = true;

        
        //this.$router.push('/Welcome');
      },

      type_select(type_index){
        this.PRODUCT_CLASS = type_index;
        this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex}});
      },      

      initpage(){
        if(this.issale == 1){
          //이벤트 정보 가져오기
          this.loadevent();

          
        }
        this.loadpage(this.page);
      },      

      //검색
      search_run(){
        //this.$router.push({path:"ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, stype:this.issale}});
        //this.loadpage(1);
        this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex}}).catch(()=>{
            //동일 페이지 오류면
            this.loadpage(1);
        });
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        //console.log("page : " + this.page + " / p : " + p);
        if(this.page == p){
          //오류상황인데 갱신해줘라.
          this.$router.go(this.$router.currentRoute);
        }else{
          this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:p, rtype:0, isquick:this.isquick, stype:this.issale, dtype:this.dtype, vivid:this.vivid, curidx:this.currentIndex}});
        }
        //this.loadpage(p);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);       

          let api_url =  "/GuestProductList";
          let api_header = {};
          if(this.$session.get("GRADE") > 1){
            api_url =  "/GradeProductList";
            api_header = { headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")} };
          }
          
          this.$http.post(this.$host + api_url,{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , issale : this.issale
              , isquick : this.isquick
              , page: this.page
              , pagerow: this.pagerow
              , PRODUCT_CLASS : this.PRODUCT_CLASS
              , search_min_price: this.search_min_price != null && parseInt(this.search_min_price) > 0 ? this.search_min_price.toString() : null
              , search_max_price: this.search_max_price != null && parseInt(this.search_max_price) > 0 ? this.search_max_price.toString() : null
              , search_min_price2: this.search_min_price2 != null && parseInt(this.search_min_price2) > 0 ? this.search_min_price2.toString() : null
              , search_max_price2: this.search_max_price2 != null && parseInt(this.search_max_price2) > 0 ? this.search_max_price2.toString() : null
              , search_shape: this.search_shape
              , search_color: this.search_color
              , search_transparency: this.search_transparency
              , search_grind_total: this.search_grind_total
              , search_grind_polish: this.search_grind_polish
              , search_grind_syme: this.search_grind_syme
              , search_fluorescence: this.search_fluorescence
              , search_diamondcolor: this.search_diamondcolor
              , search_diamondtype: this.search_diamondtype
              , search_gemstone_type: this.search_gemstone_type
              , search_min_weight: this.search_min_weight
              , search_max_weight: this.search_max_weight
              , search_gem_min_weight: this.search_gem_min_weight
              , search_gem_max_weight: this.search_gem_max_weight
              , search_jewely_metalweight: this.search_jewely_metalweight
              , search_jewely_metaltype: this.search_jewely_metaltype
              , search_jewely_item: this.search_jewely_item

              , search_jw_min_weight: this.search_jw_min_weight
              , search_jw_max_weight: this.search_jw_max_weight
              , search_jw_min_gram: this.search_jw_min_gram
              , search_jw_max_gram: this.search_jw_max_gram
              , search_jw_name: this.search_jw_name == null || this.search_jw_name == undefined ?  '' : escape(this.search_jw_name)

              , search_ds_min_weight: this.search_ds_min_weight
              , search_ds_max_weight: this.search_ds_max_weight
              , search_ds_metalweight: this.search_ds_metalweight
              , search_ds_metaltype: this.search_ds_metaltype
              
              , search_shopnumber: this.search_shopnumber
              //, search_shopnumber: String(this.search_shopnumber).replace("0210-","")

              , search_certi: this.search_certi
              , search_gem_certi: this.search_gem_certi
          }, api_header)
          .then((result)=>{
            this.first_flag = true;
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                
                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                //console.log(JSON.stringify(result.data.resultData));
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                
                this.loading = false;


            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.first_flag = true;
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              /*
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              */
            }
          });

      },



      loadevent(){
          
          this.$http.post(this.$host + "/GuestEventList",{
          }, {})
          .then((result)=>{
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.eventList.splice(0, this.eventList.length);   //데이터 초기화
                list.forEach(element => {
                    this.eventList.push(element); 
                });

                if(this.eventList.length > 0){
                  
                  /*
                  let text1 = document.getElementById("text1");
                  let text2 = document.getElementById("text2");
                  if(this.textFlag){
                    //text1 진입, text2 아웃
                    text1.innerText = this.eventList[this.currentIndex].TITLE;
                    text1.animate(this.inFrame, this.aniOption);
                    text2.animate(this.outFrame, this.aniOption);
                    this.textFlag = false;
                  }else{
                    //text2 진입, text1 아웃
                    text2.innerText = this.eventList[this.currentIndex].TITLE;
                    text1.animate(this.outFrame, this.aniOption);
                    text2.animate(this.inFrame, this.aniOption);
                    this.textFlag = true;
                  }

                  this.timer = setInterval(()=>{
                    
                    this.currentIndex = this.currentIndex + 1 >= this.eventList.length ? 0 : this.currentIndex + 1;
                    console.log("timer.... : " + this.currentIndex);
                    let text1 = document.getElementById("text1");
                    let text2 = document.getElementById("text2");
                    if(this.textFlag){
                      //text1 진입, text2 아웃
                      text1.innerText = this.eventList[this.currentIndex].TITLE;
                      text1.animate(this.inFrame, this.aniOption);
                      text2.animate(this.outFrame, this.aniOption);
                      this.textFlag = false;
                    }else{
                      //text2 진입, text1 아웃
                      text2.innerText = this.eventList[this.currentIndex].TITLE;
                      text1.animate(this.outFrame, this.aniOption);
                      text2.animate(this.inFrame, this.aniOption);
                      this.textFlag = true;
                    }
                  }, 5000);
                  */

                }

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.first_flag = true;
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },      

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },

      //조건만 초기화 하고 검색하지 않음.
      clear_search_where(){

        //다이아
        this.search_shape.splice(0, this.search_shape.length);
        this.search_color        = [0, 10];
        this.search_transparency = [0, 11];
        this.search_grind_total  = [0,6];
        this.search_grind_polish = [0,6];
        this.search_grind_syme   = [0,6];
        this.search_fluorescence.splice(0, this.search_fluorescence.length);
        this.search_diamondcolor.splice(0, this.search_diamondcolor.length);

        if(this.showDiamondType){
          //다이아몬드 타입을 사용하는 경우에만 초기화 한다. (천연, 합성 선택시 초기화 하면 안됨)
          this.search_diamondtype.splice(0, this.search_diamondtype.length);
        }
        
        //젬스톤
        this.search_gemstone_type.splice(0, this.search_gemstone_type.length);
        this.search_gem_min_weight = 0.1;
        this.search_gem_max_weight = 100;
        //this.search_range_gemweight = [0, 30];
        this.search_gem_certi.splice(0, this.search_gem_certi.length);

        //다이아몬드 잼스톤
        this.search_min_weight = 0.1;
        this.search_max_weight = 100;
        //this.search_range_weight = [0, 20];

        if(this.vivid == 0){
          //비비드면 초기화 금지
          this.search_certi.splice(0, this.search_certi.length);
        }
        

        //주얼리
        this.search_jw_min_weight  = 0;
        this.search_jw_max_weight  = 100;
        //this.search_range_jwweight = [0, 30];
        this.search_jw_min_gram  = 0;
        this.search_jw_max_gram  = 100;
        //this.search_range_jwgram = [0, 100];
        this.search_jw_name = '';

        this.search_jewely_metalweight.splice(0, this.search_jewely_metalweight.length);
        this.search_jewely_metaltype.splice(0, this.search_jewely_metaltype.length);
        this.search_jewely_type = [];
        this.search_jewely_item.splice(0, this.search_jewely_item.length);
        this.SELECTTITEMLIST.splice(0, this.SELECTTITEMLIST.length);

        this.SELECTTITEMMAP = {};
        this.PRODUCTTYPELIST.forEach(element=>{
          this.SELECTTITEMMAP[element.IDX] = [];
        });
        
        //디자인 세공
        this.search_ds_min_weight = 0;
        this.search_ds_max_weight = 100;
        this.search_ds_metalweight.splice(0, this.search_ds_metalweight.length);
        this.search_ds_metaltype.splice(0, this.search_ds_metaltype.length);

        //공통
        this.search_min_price = null;
        this.search_max_price = null;
        this.search_min_price2 = null;
        this.search_max_price2 = null;
        
        this.search_shopnumber = null;

        this.saveSearchOption();  //검색조건 저장

      },

      clear_search(){
        //검색필터 초기화 후 검색
        this.search_dialog = false;
        this.clear_search_where();
        this.search_run();
        //this.$router.push({path:"ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:0}});
      },

      apply_search(){
        //검색필터 적용 후 검색
        this.search_dialog = false;
        this.saveSearchOption();  //검색조건 저장
        this.search_run();
      },

      goEventDetail(item){
        this.$router.push({path:"/EventDetail", query:{code:item.EVENT_IDX}});
      },

    },



}
</script>

<style scoped>
.active-label {
  color: red; /* 원하는 텍스트 컬러로 변경하세요 */
}

.imgscaleup{
  overflow: hidden;
  transform:scale(1.2);
  transition: transform 1s;
}

.fixed_rightbottom_mb{
  position: fixed;
  right: 5px;
  bottom: 25px;
  z-index: 10;
}

.fixed_rightbottom{
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 10;
}

.grid {
	list-style: none;
	margin: 0 auto;
	text-align: left;
	width: 100%;
}

.grid li {
	display: inline-block;
	position: relative;
	width: 25%;
}

.grid li:after {
	content: "";
	display: block;
	padding-bottom: 52%;
}

.grid li a {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 2px solid #fff;
	height: 99%;
	position: absolute;
	width: 98%;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.grid li a:hover {
	border: 2px solid #000;
	box-shadow:         inset 0 0 90px -10px rgba( 0, 0, 0, 1 );
	-moz-box-shadow:    inset 0 0 90px -10px rgba( 0, 0, 0, 1 );
	-webkit-box-shadow: inset 0 0 90px -10px rgba( 0, 0, 0, 1 );
  transform:scale(1.1);
  transition: transform 1s;
}

.text_pc_prov{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #919191;
}

.text_pc_name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.text_pc_price{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.text_searchdialog_subtitle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
}

.text_searchdialog_subtitle2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #919191;
}

.text_2line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_sale{
  position:absolute;
  color:white;
  background: black;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  margin-top:5px;
  margin-left:5px;
  border-radius: 15px;
  font-size: 11px;
  border: 2px yellow solid;
}

.text_ship{
  position:absolute;
  color:white;
  background: black;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  bottom:5px;
  margin-left:5px;
  border-radius: 15px;
  border: 2px yellow solid;
}

.avatar_img{
  height: 50px !important;
  width: 50px !important;
}

.v-chip{
  box-shadow: 1px 1px 1px #808080;
}

.v-slide-item--active {
  background: #9cdafd;
  border: solid 2px #0d5bf2
}

.vselectstyle{
  min-height: 20px !important;
  height: 20px !important;
}

.v-btn-toggle > .v-btn.v-btn {
  opacity: 1.0 !important;
}

.teststyle{
  width: calc(window.innerWidth/5);
}

.v-expansion-panels>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-header>>> .v-expansion-panel-header__icon {
  margin: 0 !important;
}

/* 체크박스의 전체 스타일 설정 */
.custom-checkbox{
  /* 체크박스의 배경 색상 설정 */
  background-color: #ffcc00 !important;
  accent-color: #000000;
  /* 체크박스 크기 조정 */
  width: 18px;
  height: 18px;
}

/* 체크된 상태의 체크박스 스타일 설정 */
.custom-checkbox:checked {
  /* 체크된 상태일 때의 색상 설정 */
  background-color: #00ff00 !important;
  accent-color: #000000;
}

.scroll-container {
  overflow: auto; /* 스크롤바 표시를 위해 오버플로우 속성 설정 */
  /* 스크롤바 스타일 설정 */
  scrollbar-width: thin; /* 스크롤바 너비 설정 (thin, auto, none 중 선택) */
  scrollbar-color: #000000 #777777; /* 스크롤바의 색상 설정 */
}

/* Webkit 브라우저를 위한 스크롤바 스타일 설정 */
.scroll-container::-webkit-scrollbar {
  width: 10px; /* 스크롤바 너비 설정 */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #000000; /* 스크롤바 색상 설정 */
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #777777; /* 스크롤바 트랙 색상 설정 */
}


</style>